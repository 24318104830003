import React, {useEffect, useState} from 'react';
import {Button,Row, Col, Modal, Form} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import { createQuizQuestion } from '../../actions/quiz';


function QuizOptions({options, addNewOption, set_options}){

  useEffect(()=>{

  }, [options])

  function isCorrectOption(id){
    let temp = options;
    temp.find(o=>o.correct===true).correct = false;
    temp.find(o=>o.id===id).correct = true;
    set_options([...temp])
    console.log(options)
  }

  function handleOnChange(){

  }

  function handleOptionUpdate(text, option){
    options.find(o=>o.id === option.id).value = text;
    set_options([...options])
  }

  return (
    <Form.Group>
      {/* <pre>{JSON.stringify(options, null, 2)}</pre> */}
      {options && options.map((opt)=><Row>
        <Col>
          <Form.Group controlId="option-1-value">
            <Form.Control type="text" onChange={(e)=>handleOptionUpdate(e.target.value, opt)} placeholder={"new option "+opt.id} />
          </Form.Group>
        </Col>
        <Col xs="auto">
          <Form.Group controlId="option-1-radio">
            <Form.Check checked={opt.correct} onChange={()=>handleOnChange()} onClick={()=>isCorrectOption(opt.id)} type="radio" name="correct-option" label="Correct" />
          </Form.Group>
        </Col>
      </Row>)}
      <Form.Group>
        <Button variant="secondary" onClick={()=>addNewOption()}>
          Add Option
        </Button>
      </Form.Group>
    </Form.Group>
  )
}

function AddQuizQuestionModel({show, handleClose, handleShow, quiz_id}){

  const dispatch = useDispatch();
  const [title, set_title] = useState("");
  const [options, set_options] = useState([
    {id: 1, value: "Option 1", correct: true},
    {id: 2, value: "Option 2", correct: false},
  ]);
  const [question_body, set_question_body] = useState("");
  const [video_url, set_video_url] = useState("");
  const [explanation, set_explanation] = useState("");


  useEffect(()=>{

  }, [show, handleShow, handleClose, quiz_id])

  function findCorrectOption(){
    let option = options.find(o=>o.correct === true);
    return option.id;
  }

  function handleCreate(){

    const Question = {
      "title": title,
      "body": question_body,
      "options": options,
      "answer": findCorrectOption(),
      "quiz_id": quiz_id,
      "video_explanation": video_url,
      "explanation": explanation
    }
    
    dispatch(createQuizQuestion(Question))
    handleClose();
  }

  function addNewOption(){
    options.push({id: options[options.length-1].id+1, value: "new option", correct: false})
    set_options([...options]);
  }

  return(
    <Modal style={{zIndex:1000000}} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Question in Quiz</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
        <Form.Group controlId="question_title">
          <Form.Label>Title</Form.Label>
          <Form.Control onChange={(e)=>set_title(e.target.value)} type="text" placeholder="Title" />
        </Form.Group>
        <Form.Group controlId="question_body">
          <Form.Label>Body</Form.Label>
          <Form.Control as="textarea" onChange={(e)=>set_question_body(e.target.value)} rows={3} placeholder="Question Body" />
        </Form.Group>
        <Form.Group controlId="question_video_explanation">
          <Form.Label>Video Explanation (Optional)</Form.Label>
          <Form.Control type="text" onChange={(e)=>set_video_url(e.target.value)} placeholder="https://www.youtube.com/embed/YOUR_VIDEO_ID" />
        </Form.Group>
        <Form.Group controlId="question_explanation">
          <Form.Label>Question Explanation (Optional)</Form.Label>
          <Form.Control as="textarea" onChange={(e)=>set_explanation(e.target.value)} rows={3} placeholder="Explanation" />
        </Form.Group>
        <QuizOptions options={options} addNewOption={addNewOption} set_options={set_options} />
      </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleCreate}>
          Add Question
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default AddQuizQuestionModel;
