import React, {useEffect, useState} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';

import {
  createQuiz
} from './../../actions/quiz';


import {useDispatch} from 'react-redux';


function CreateQuizModel({show, handleClose, handleShow}){

  const dispatch = useDispatch();
  const [title, setTitle] = useState("");

  useEffect(()=>{

  }, [show, handleShow, handleClose])


  function handleCreate(){

    dispatch(createQuiz({
      title: title
    }))
    
    handleClose();
    
  }

  return(
    <Modal style={{zIndex:1000000}} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Quiz</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
        <Form.Group controlId="courseTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control onChange={(e)=>{
            setTitle(e.target.value)
            setTitle(e.target.value)
          }} type="text" placeholder="Quiz Title" />
          <Form.Text className="text-muted">
            Quiz Title Ex. Python Quiz
          </Form.Text>
        </Form.Group>
      </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleCreate}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default CreateQuizModel;
