import React, {useEffect, useState} from 'react';
import './style.scss';
import {Button, Modal, Form, Row, Col} from 'react-bootstrap';
import ReactPlayer from 'react-player';

import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import axios from 'axios';

import {BASE_URL} from './../../config';

import {
  updateCourse
} from './../../actions/course';

import uploadService from './../../utils/uploader';

import {useDispatch} from 'react-redux';


const mdParser = new MarkdownIt({

});



function handleImageUpload (file) {
 return new Promise(resolve => {
   const reader = new FileReader();
   reader.onload = data => {
     let url = data.target.result;
     let fileName = Math.random().toString(36).substring(7)+"-"+file.name;
     let filetype = file.type;
     fetch(url)
    .then(res => res.blob())
    .then(blob => {
      const file = new File([blob], fileName ,{ type: filetype })
      axios.post(`${BASE_URL}/upload/s3signurl`, {
        filename: fileName,
        filetype: filetype
      })
      .then((response)=>{
        console.log(response.data);
        
        axios.put(response.data.url,file,{
          "headers": {
            "content-type": undefined,
          }
        })
        .then((response)=>{
          axios.get(`${BASE_URL}/upload/s3signurl?file=`+fileName)
          .then((response)=>{
            console.log(response.data.url);
            resolve(response.data.url)
          })
        })
      })
    })

   };
   reader.readAsDataURL(file);
 });
};

function UpdateCourseModel({show, handleClose, handleShow, match, course}){

  const dispatch = useDispatch();

  const course_data = JSON.parse(JSON.stringify(course));


  const [courseKey, setCourseKey] = useState(course_data.key);
  const [title, setTitle] = useState(course_data.title);
  const [description, setDescription] = useState("");
  const [slug, setSlug] = useState("");
  const [visible, setVisible] = useState(true);
  const [fee, setFee] = useState(0);
  const [goal, setGoal] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [socialImage, setSocialImage] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [discount, setDiscount] = useState(0);
  const [intro_video, set_intro_video] = useState("");

  useEffect(()=>{
    setCourseKey(course_data.key);
    setTitle(course_data.title)
    setDescription(course_data.description)
    setSlug(course_data.slug)
    setFee(course_data.fee)
    setGoal(course_data.goal)
    setVisible(course_data.private)
    setBannerImage(course_data.banner_image)
    setCardImage(course_data.card_image)
    setSocialImage(course_data.social_image)
    setDiscount(course_data.discount)
    set_intro_video(course_data.intro_video)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, handleShow, handleClose])

  function handleEditorChange({ text}) {
    setDescription(text);
  }

  function handleUpdate(){
    const course = {
      course_id: courseKey,
      title: title,
      description: description,
      slug: slug,
      private: visible,
      fee: parseInt(fee),
      goal: goal,
      banner_image: bannerImage,
      card_image: cardImage,
      social_image: socialImage,
      discount: parseInt(discount),
      intro_video: intro_video
    };
    dispatch(updateCourse(course))
    handleClose();
  }

  return(
    <Modal style={{zIndex:1000000}} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Course</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
        <Form.Group controlId="courseTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control onChange={(e)=>{
            setTitle(e.target.value)
            setSlug(e.target.value.toLowerCase().replace(/\s+/g, '-'))
          }} type="text" placeholder="Title" value={title}/>
          <Form.Text className="text-muted">
            Course Title Ex. Learn Cooking
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="courseDescription">
          <Form.Label>Description</Form.Label>
          <MdEditor
          value={description}
          style={{ height: "500px" }}
          renderHTML={(text) => mdParser.render(text)}
          onChange={handleEditorChange}
          onImageUpload={handleImageUpload}
          />
          <Form.Text className="text-muted">
            Enter Course Description here
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="courseSlug">
          <Form.Label>Slug</Form.Label>
          <Form.Control value={slug} onChange={(e)=>setSlug(e.target.value)} type="text" placeholder="Slug" />
          <Form.Text className="text-muted">
            Course Slug Ex. Learn-Cooking
          </Form.Text>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label as="legend" column sm={2}>
            Visible
          </Form.Label>
          <Col sm={10}>
            <Form.Check
              onChange={(e)=>setVisible(true)}
              type="radio"
              label="Public"
              checked={visible}
              name="courseVisibilityStatus"
              id="formHorizontalRadios1"
            />
            <Form.Check
              onChange={(e)=>setVisible(false)}
              type="radio"
              label="Private"
              checked={visible}
              name="courseVisibilityStatus"
              id="formHorizontalRadios2"
            />
          </Col>
        </Form.Group>
        <Form.Group controlId="courseGoal">
          <Form.Label>Goal</Form.Label>
          <Form.Control value={goal} onChange={(e)=>setGoal(e.target.value)} type="text" placeholder="Cooking" />
          <Form.Text className="text-muted">
            Course Goal ex. Cooking, Programming, Music
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="courseFee">
          <Form.Label>Fee</Form.Label>
          <Form.Control value={fee} onChange={(e)=>setFee(e.target.value)} type="number" placeholder="Fee" />
          <Form.Text className="text-muted">
            Course Fee
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="courseDiscount">
          <Form.Label>Discount (%)</Form.Label>
          <Form.Control value={discount} onChange={(e)=>setDiscount(e.target.value)} type="number" placeholder="Discount %" />
          <Form.Text className="text-muted">
            Discount %
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <div>
            <Button variant="link" href={bannerImage}>
              <img src={bannerImage} alt="d" className="model-image-preview" />
            </Button>
          </div>
          <Form.File onChange={
            (file)=>{
              uploadService(file, (p)=>{
                console.log(p);
              }, (resp)=>{
                console.log(resp);
                setBannerImage(resp.url)
              });
            }
          } label="Course Banner Image (1400 Px x 500 Px)" />
        </Form.Group>
        <Form.Group>
        <Button variant="link" href={cardImage}>
          <img src={cardImage} alt="sf" className="model-image-preview" />
        </Button>
          <Form.File onChange={
            (file)=>{
              uploadService(file, (p)=>{
                console.log(p);
              }, (resp)=>{
                console.log(resp);
                setCardImage(resp.url)
              });
            }
          } label="Course Card Image (1000 Px x 1000 Px)" />
        </Form.Group>
        <Form.Group>
        <Button variant="link" href={socialImage}>
          <img src={socialImage} alt="jk" className="model-image-preview" />
        </Button>
          <Form.File onChange={
            (file)=>{
              uploadService(file, (p)=>{
                console.log(p);
              }, (resp)=>{
                console.log(resp);
                setSocialImage(resp.url)
              });
            }
          } label="Course Social Banner (800 Px x 500 Px)" />
        </Form.Group>
        <Form.Group>
          <div className="model-image-preview">
            <ReactPlayer volume={0.8} controls={true} url={intro_video} />
          </div>
          <Form.Group controlId="courseDiscount">
            <Form.Label>Intro Video URL</Form.Label>
            <Form.Control value={intro_video} onChange={(e)=>set_intro_video(e.target.value)} type="text" placeholder="Video URL" />
            <Form.Text className="text-muted">
              Provide Your Video Public URL ex. Youtube (https://www.youtube.com/embed/your_video_id)
            </Form.Text>
          </Form.Group>
        </Form.Group>
      </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default UpdateCourseModel;
