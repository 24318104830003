import axios from 'axios'

import {BASE_URL} from './../config';

export const USER_FETCH_WITH_TOKEN_REQUEST  = 'USER_FETCH_WITH_TOKEN_REQUEST'
export const USER_FETCH_WITH_TOKEN_SUCCESS  = 'USER_FETCH_WITH_TOKEN_SUCCESS'
export const USER_FETCH_WITH_TOKEN_FAILURE  = 'USER_FETCH_WITH_TOKEN_FAILURE'


export function fetchUserWithToken(token){
  return dispatch => {
    dispatch({
      type: USER_FETCH_WITH_TOKEN_REQUEST,
      user: null
    })
    return axios.get(`${BASE_URL}/user/token`, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }}).then((response)=>{
      const user = response.data;
      dispatch({
        type: USER_FETCH_WITH_TOKEN_SUCCESS,
        user
      })
    }).catch((error)=>{
      dispatch({
        type: USER_FETCH_WITH_TOKEN_FAILURE,
        user: null
      })
    })

  }

}
