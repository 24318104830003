import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { changeLanguage, availableLang} from './../../locales/config';


const options = availableLang;
const lang = localStorage.getItem('lang');
const userLang = JSON.parse(lang);
export const SelectLanguage = () => {
    const [defaultLang] = useState(userLang || { value: 'en', label: 'English' });
    const getUserLang = () => {
        if (userLang) {
            changeLanguage(userLang.value);
        }
    }

    useEffect(() => {
        getUserLang();
    }, []);

    useEffect(() => {
        console.log('get updated!')
    }, [defaultLang]);

    const SelectLang = (option) => {
        changeLanguage(option.value)
        localStorage.setItem('lang', JSON.stringify(option));
    }
    return (
        <Fragment>
            <Select
                options={options}
                onChange={SelectLang}
                defaultValue={defaultLang}
            />
        </Fragment>
    )
}
