import axios from 'axios'
import history from './../utils/history';

import {BASE_URL} from './../config';

export const LOGIN_REQUEST  = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS  = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE  = 'LOGIN_FAILURE'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const TOKEN_MISSING = 'TOKEN_MISSING'
export const TOKEN_VERIFY = 'TOKEN_VERIFY'

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export function requestLogin(user) {
  return dispatch => {
    dispatch({
      type: LOGIN_REQUEST,
      isFetching: true,
      isAuthenticated: false
    })
    dispatch(login(user))
  }
}

function receiveLogin(user) {
  // save token
  localStorage.setItem('token', user.token);

  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user: user.user,
    token: user.token
  }
}

function loginError(error) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    error: error.message
  }
}

export function login(user){

  return dispatch => {
    return axios.post(`${BASE_URL}/login`, {
      email: user.username,
      password: user.password,
      social: user.social?true:false,
      accessToken: user.accessToken?user.accessToken:null,
      fullname: user.fullname?user.fullname:null
    }).then((response)=>{
      localStorage.setItem('auth', true);
      const user = response.data;
      const redirect_url = localStorage.getItem('redirect');
      if (redirect_url){
        history.push(new URL(redirect_url).pathname)
        localStorage.removeItem('redirect');
        window.location.reload();
        dispatch(receiveLogin(user));
        return;
      }else{
        history.push("/dashboard");
        window.location.reload();
        dispatch(receiveLogin(user));
        return;
      }
    }).catch((error)=>{
      console.log(error);
      localStorage.setItem('auth', false);
      dispatch(loginError(error.response.data));
    })

  }
}

function dispatchLogout() {
  return {
    type: LOGOUT_SUCCESS,
  }
}

// Logs the user out
export function logoutUser() {
  localStorage.removeItem('token');
  localStorage.removeItem('auth');
  return dispatch => {
    dispatch(dispatchLogout())
    history.push('/')
  }
}


export function signupRequest(user){
  return dispatch => {
    console.log(user);
    dispatch({
      type: SIGNUP_REQUEST,
      isFetching: true,
      isAuthenticated: false
    })
    dispatch(signupUser(user))
  }
}


export function signupSuccess(user){
  console.log("signup success ", user);
  return {
    type: SIGNUP_SUCCESS,
    isAuthenticated: true,
    isFetching: false,
    token: user.token,
  }
}


export function signupError(error){
  return {
    type: SIGNUP_FAILURE,
    error
  }
}

export function signupUser(user){
  return dispatch => {
    return axios.post(`${BASE_URL}/signup`,{
      email: user.email,
      password: user.password,
      student: user.accountType,
      mobile: user.mobile,
      first_name: user.fullname.split(' ')[0],
      last_name: user.fullname.split(' ').pop()
    }).then((response)=>{
      const user = response.data;
      localStorage.setItem('token', user.token);
      localStorage.setItem('auth', true);
      dispatch(signupSuccess(user))
      history.push('/dashboard')
    }).catch((error)=>{
      localStorage.setItem('auth', false);
      dispatch(signupError(error.response.data))
    })
  }
}

export function loginWithToken(token){
  return dispatch => {
    return axios.post(`${BASE_URL}/verifytoken`, {
      token
    })
    .then((response)=>{
      console.log(response);
      localStorage.setItem('auth', true);
      
      try{
        localStorage.removeItem('redirect');
      }catch(e){
        console.log(e)
      }

      const user = {
        token: response.data.token,
        uid: response.data.uid
      };
      dispatch(receiveLogin(user))
    })
    .catch((error)=>{
      localStorage.setItem('auth', false);
      dispatch(loginError(error.response.data))
    })
  }
}

export function forgotPassword(email){
  return dispatch => {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST
    })
    return axios.post(`${BASE_URL}/resetpassword`, {
      email
    })
    .then((response)=>{
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        message: 'Password Reset Link Shared, Please check your Inbox'
      })
      alert('Please Check Your Inbox!')
      history.push("/");
      window.location.reload();
    })
    .catch((error)=>{
      dispatch({
        type: FORGOT_PASSWORD_FAILURE,
        message: 'Forgot Password Request Failed'
      })
    })
  }
}


export function updatePassword(email, password, password_token){
  return dispatch => {
    dispatch({
      type: UPDATE_PASSWORD_REQUEST
    })
    return axios.post(`${BASE_URL}/updatepassword`, {
      email,
      password,
      password_token
    })
    .then((response)=>{
      dispatch({
        type: UPDATE_PASSWORD_SUCCESS,
        message: 'Password Updated Successfully'
      })
      history.push("/login");
      window.location.reload();
    })
    .catch((error)=>{
      dispatch({
        type: UPDATE_PASSWORD_FAILURE,
        message: 'update password failed'
      })
    })
  }
}
