import React, { Fragment } from 'react';
import AceEditor from "react-ace";
import { Button, InputGroup, Form } from 'react-bootstrap';
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import './styles.scss';
import { useState } from 'react';
import { BASE_URL } from './../../../config';
import { useEffect } from 'react';
interface ManageApplicationConfigProps {
    config?: {
        name: string;
        custom_config: any;
    };
}

const ManageApplicationConfig = (props: ManageApplicationConfigProps) => {
    const [editorValue, setEditorValue] = useState("");
    const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);
    const [appName, setAppName] = useState('your-app-name');
    const [myConfig, setMyConfig] = useState({
        name: 'your-app-name',
        custom_config: {}
    });

    function getUserConfig() {
        let token = localStorage.getItem('token') || null;
        fetch(`${BASE_URL}/config`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(response => response.json())
            .then((data) => {
                setAppName(data.name);
                setMyConfig({
                    name: data.name,
                    custom_config: data.custom_config
                });
                setEditorValue(data.custom_config);
            })
    }

    function onChange(newValue) {
        console.log(newValue);
        setEditorValue(newValue);
        setMyConfig({
            name: appName,
            custom_config: newValue
        });
        
    }

    function onSave() {
        let token = localStorage.getItem('token') || null;
        fetch(`${BASE_URL}/config`, {
            method: 'PUT',
            body: JSON.stringify({
                name: appName,
                custom_config: myConfig.custom_config
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(response => response.json())
            .then((data) => {
                setDisabledSaveBtn(true);
            })
    }

    useEffect(() => {
        getUserConfig();
    }, []);



    return (
        <Fragment>
            <div>
                <h1>Manage Application Config</h1>
                <div className='app-name'>
                    <InputGroup hasValidation>
                        <Form.Control type="text" onChange={(e) => setAppName(e.target.value)} value={appName} required isInvalid={false} />
                        <InputGroup.Text>.learnwithguruji.com</InputGroup.Text>
                        <Button disabled={disabledSaveBtn} onClick={() => onSave()}>Save</Button>
                        <Form.Control.Feedback type="invalid">
                            Please choose a application username.
                        </Form.Control.Feedback>
                    </InputGroup>
                </div>
                <div className='app-config'>
                    <AceEditor
                        value={editorValue}
                        mode="json"
                        theme="github"
                        onChange={onChange}
                        name="application-config"
                        editorProps={{ $blockScrolling: true }}
                    />
                </div>

            </div>
        </Fragment>
    );
}

export default ManageApplicationConfig;