import React from 'react';
import './style.scss';

import {Carousel} from 'react-bootstrap';


import featuredBannerBgImg from './../../assets/banner-image.png';

function FeatureBanner({featuredCourses}) {
    return (
      <>
      <Carousel>
        {featuredCourses?(
          featuredCourses.map((course, i)=>{
            return (
              <Carousel.Item key={"banner_"+i}>
                <img
                  className="d-block w-100 banner-image"
                  src={course.banner_image || featuredBannerBgImg}
                  alt={course.title}
                  />
                  <img
                    className="d-block w-100 mobile-card"
                    src={course.card_image || featuredBannerBgImg}
                    alt={course.title}
                />
                <Carousel.Caption>
                  <a className="btn btn-success banner-button" href={"/course/"+course.id}>View Course</a>
                </Carousel.Caption>
              </Carousel.Item>
            )
          })
        ):(<></>)}


        </Carousel>
      </>
    )
}

export default FeatureBanner;
