import React, {useEffect, useState} from 'react';
import './style.scss';
import {Button, Modal, Form} from 'react-bootstrap';



import {BASE_URL} from './../../config';



function InviteCourseModel({show, handleClose, handleShow, match, course}){


  const [emails, setEmails] = useState("");

  useEffect(()=>{

  }, [show, handleShow, handleClose])

  function handleInvite(){
    let token = localStorage.getItem('token') || null;
    console.log(parseInt(course.id),emails);
    fetch(`${BASE_URL}/course/invite`, {
      method: "POST",
      body: JSON.stringify({
        course_id: parseInt(course.id),
        emails: emails
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+token,
       }
    }).then((response)=>response.json())
    .then((data)=>{
      console.log(data);
      handleClose();
    })
  }
  return(
    <Modal style={{zIndex:1000000}} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Invite</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
        <Form.Group controlId="courseTitle">
          <Form.Label>Emails (Use ',' for multiple emails)</Form.Label>
          <Form.Control onChange={(e)=>{
            setEmails(e.target.value)
          }} as="textarea" rows={3} placeholder="zyx@gmail.com" value={emails}/>
          <Form.Text className="text-muted">
            Invite Max 50 Contact at a time.
          </Form.Text>
        </Form.Group>

      </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleInvite}>
          Send Invitation
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default InviteCourseModel;
