import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import store from "./store";
import { Provider } from 'react-redux'
import history from './utils/history';

import './locales/config';
if (window.location && window.location.hostname !== "localhost") {
  console.log = function () { }
}
ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>
  ,
  document.getElementById('root')
);
