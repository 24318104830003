import axios from 'axios';
import {BASE_URL} from './../config';
import Papa from 'papaparse';

const expxort_csv = (quiz_id) => {
    let token = localStorage.getItem('token') || null;

    axios.post(`${BASE_URL}/quiz/${quiz_id}/submissions`, {}, {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token,
        }
    })
        .then((response) => {
            var data = response.data;
            var newdata = data[0];
            console.log(newdata);
            var array = newdata.quiz_questions;
            console.log(array);
            var link = document.createElement('a');
            var file = "data:text/csv;charset=utf-8,";
            var list = [];
            for (let i = 0; i < array.length; i++) {
                var myjson = {};
                myjson['id']=array[i]['id'];
                myjson['body']=array[i]['body'];
                var submission = JSON.stringify(newdata.quiz_submissions[(newdata.quiz_submissions.length)-1]['answer']);
                var status = submission === array[i]['answer'];
                var json = {'QUIZ ID':newdata.id,'USER ID':newdata.user_id,'TITLE':newdata.title, 'PUBLISHED':newdata.published,'QUESTION ID':myjson['id'],
                'QUESTION':myjson['body'],'ANSWER':array[i]['answer'],'SUBMISSION':submission,'STATUS':status};
                list.push(json);
            }
            var parse = Papa.unparse(list);
            var encodedUri = encodeURI(parse);
            link.setAttribute("href", file + encodedUri);
            link.setAttribute("download", "quiz_data.csv");
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {

        })
}

export default expxort_csv;