

export const authMiddleware = store => next => action => {
  let token = localStorage.getItem('token') || null;


  if (token){
    next({
      type: "TOKEN_VERIFY",
      token: token,
    })

  }else{
    next({
      type: "TOKEN_MISSING",
      error: ''
    })
  }
  next(action)
}
