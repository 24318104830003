import axios from "axios";
import { BASE_URL } from "../config";

export const fileUploadS3 = (event, uploadProgress, callback) => {
    let file = event.target.files[0];
    let fileName = Math.random().toString(36).substring(7) + "-" + event.target.files[0].name;
    let fileType = event.target.files[0].type;
    axios.post(`${BASE_URL}/upload/s3signurl`, {
        filename: fileName,
        filetype: fileType
    }).then((response) => {
        console.log(response.data);
        axios.put(response.data.url, file, {
            "headers": {
                "content-type": undefined,
            },
            onUploadProgress: function (progressEvent) {
                uploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            }
        })
        .then((response) => {
            axios.get(`${BASE_URL}/upload/s3signurl?file=` + fileName)
                .then((response) => {
                    const token = localStorage.getItem('token') || null;
                    axios.post(`${BASE_URL}/upload`, {
                        type: fileType,
                        url: response.data.url
                    }, { 'headers': { 'Authorization': "Bearer " + token } })
                        .then((resp) => {
                            callback(resp);
                        })
                })
        })
    })
}