import React, {useEffect, useState} from 'react';

import GoogleLoginButton from '../google-button';

import { useSelector, useDispatch } from 'react-redux';


import {
    fetchUserWithToken
} from './../../actions/user';
  
import { Modal} from 'react-bootstrap';

function LoginRequired(){

    const dispatch = useDispatch();

    const isAuthenticated = useSelector(state => {
      return state.auth.isAuthenticated
    })

    const token = useSelector(state => {
      return state.auth.token
    })

    

    const [show] = useState(true);

    useEffect(()=>{
      dispatch(fetchUserWithToken(token))
 
    }, [dispatch, token])

    

    return (
        <>
        {!isAuthenticated && <Modal show={show}>
            <Modal.Header closeButton>
                <Modal.Title>Please Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You Must login to access our services.</p>
                <GoogleLoginButton />
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>}
        </>
    )
}

export default LoginRequired

