import {

  USER_FETCH_WITH_TOKEN_REQUEST,
  USER_FETCH_WITH_TOKEN_SUCCESS,
  USER_FETCH_WITH_TOKEN_FAILURE

} from '../actions/user'

const defaultState = {
  user: {
    first_name: '',
    tutor: false
  },
}

const user = (state = defaultState, action) => {

  switch (action.type) {

    case USER_FETCH_WITH_TOKEN_REQUEST:
      return Object.assign({}, state, {
          user: defaultState.user
      });

    case USER_FETCH_WITH_TOKEN_SUCCESS:
      const user = action.user
      return {
        ...state,
        user
      }

    case USER_FETCH_WITH_TOKEN_FAILURE:
      return Object.assign({}, state, {
          user: defaultState.user
      });

    default:
      return state;

  }
}

export default user;
