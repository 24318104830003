import React, {useEffect} from 'react';
import "./styles.scss";

export default function MeetingPageComponent () {

  useEffect(()=>{

  }, [])

  return (
    <div>
      meeting
    </div>
  )
}
