import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';

interface Item {
    title: string;
    items?: Items[];
}
interface Items {
    name: string;
    text: string;
    icon: string;
    active: boolean;
}
interface DashboardSideBarProps {
    isTutor: any;
    setActiveView(viewName: string): void;
}

const DashboardSideBar = (props: DashboardSideBarProps) => {
    const { t } = useTranslation();

    const data: Item[] = [
        {
            'title': 'dashboardSideBarAnalyticsTitle',
            'items': [
                { name: 'analytics', text: 'dashboardSideBarAnalytics', icon: 'config', active: false },
            ]
        },
        {
            'title': 'dashboardSideBarMyCoursesTitle',
            'items': [
                { name: 'courses-applied', text: 'dashboardSideBarCoursesApplied', icon: 'course-applied', active: false },
            ]
        },
        {
            'title': 'dashboardSideBarManageCoursesTitle',
            'items': [
                { name: 'my-courses', text: 'dashboardSideBarMyCourses', icon: 'course', active: true },
            ]
        },
        {
            'title': 'dashboardSideBarManageApplicationTitle',
            'items': [
                { name: 'manage-application', text: 'dashboardSideBarApplicationSettings', icon: 'config', active: false },
            ]
        },
        {
            'title': 'dashboardSideBarManageWebsiteTitle',
            'items': [
                { name: 'manage-website', text: 'dashboardSideBarWebsite', icon: 'config', active: false },
            ]
        },
        {
            'title': 'dashboardSideBarUploaderTitle',
            'items': [
                { name: 'uploader', text: 'dashboardSideBarUploader', icon: 'config', active: false },
            ]
        }
    ];

    const [sideBarData, setSideBarData] = useState(data);

    // tslint:disable-next-line: no-empty
    useEffect(() => { }, [sideBarData]);
    function onShow(sideBarIndex: number, index: number) {
        for (const item in sideBarData) {
            if (sideBarData[item] && sideBarData[item].items) {
                sideBarData[item].items.map((i) => i.active = false);
            }
        }
        sideBarData[sideBarIndex].items[index].active = true;
        setSideBarData([...sideBarData]);
        props.setActiveView(sideBarData[sideBarIndex].items[index].name);

    }
    return (
        <Fragment>
            {props.isTutor && <div className='dashboard-side-bar'>
                {sideBarData && sideBarData.map((s, sideBarIndex) => <ul key={'side-bar-list-' + sideBarIndex}>
                    {<div className='side-bar-heading'>{t(s.title)}</div>}
                    {s?.items && s?.items.map((item, itemIndex) => <div onClick={() => onShow(sideBarIndex, itemIndex)} key={'side-bar-item-' + itemIndex} className={item.active ? 'side-bar-item-link side-bar-active' : 'side-bar-item-link'}>
                        {<a href='#0' className={item.icon}>
                            <span className={'side-bar-icon ' + item?.icon}></span>
                            <span className='side-bar-item-text'>{t(item?.text)}</span>
                        </a>}
                    </div>)}
                </ul>)}
                {!sideBarData && <div>loading...</div>}
            </div>}
        </Fragment>
    );
}

export default DashboardSideBar;