import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import expxort_csv from '../../utils/quiz.helper';
import { BASE_URL } from './../../config';
import { Button } from 'react-bootstrap';
import {
    getAllQuizzes
    
} from './../../actions/quiz';
function QuizCard({ quiz, handleShowQuizQuestionModel, handleShowDeleteQuizModel }) {

    const dispatch = useDispatch();
    
    const [set_publish_status] = useState(quiz.published ? 'Un-Publish' : 'Publish');

    useEffect(()=>{

    }, [quiz]);
    
    const handleQuizExport = (quiz_id) => {
        expxort_csv(quiz_id);
    }

    const PublishQuiz = (quiz_id, status) => {

        let token = localStorage.getItem('token') || null;

        fetch(`${BASE_URL}/quiz/publish`, {
            method: 'POST',
            body: JSON.stringify({
                quiz_id: quiz_id,
                publish: status
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(response => response.json())
            .then((data) => {
                set_publish_status('Published');
                dispatch(getAllQuizzes());
            })
    }

   

    return (
        <Fragment>
            <div className="col-md-12 quiz-strip">
                <h1 className="heading">{quiz.title}</h1>
                <Button variant="link" href={"/quiz/" + quiz.id}>View</Button> |
                <Button variant="link" onClick={() => handleShowQuizQuestionModel(quiz.id)} href={"#"}>Add Question</Button> |
                <Button variant="link" onClick={() => handleQuizExport(quiz.id)} href={"#"}>Export CSV</Button> |
                {!quiz.published && <Button variant="link" onClick={() => PublishQuiz(quiz.id, true)}>Publish</Button>}
                {quiz.published && <Button variant="link" onClick={() => PublishQuiz(quiz.id, false)}>Ub-Publish</Button>} |
                {!quiz.published && <Button variant="link" onClick={() => handleShowDeleteQuizModel(true)}>Delete</Button>}
            </div>
        </Fragment>
    )
}

export default QuizCard;