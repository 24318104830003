import React, { Fragment } from "react";

export const Heading = ({ id, text, styles }) => {
    return (
        <Fragment>
            <h1
                id={id}
                style={styles}
            >{text}</h1>
        </Fragment>
    );
}