import React, { Fragment, useState } from 'react';
import { Toolbar } from './components/toolbar';
import './styles.scss';
export const BuilderMain = () => {
    const [tempWidth, setTempWidth] = useState(850);
    const [tempHeight, settempHeight] = useState(610);

    const onDrop = (event) => {
        event.preventDefault();
        console.log(event.dataTransfer.getData("text/plain"));

    }

    const dragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
        console.log('drag over');

    }
    return (
        <Fragment>
            <div id='builder-container'>
                <Toolbar />
                <div id="device"
                    style={{ width: tempWidth, height: tempHeight }}
                >
                    <div>
                        ScreenWidth : <input type="number" value={tempWidth} onChange={(e) => setTempWidth(Number(e.target.value))} />
                        ScreenHeight : <input type="number" value={tempHeight} onChange={(e) => settempHeight(Number(e.target.value))} />

                    </div>
                    <div id="template"
                        onDrop={onDrop}
                        onDragOver={dragOver}
                        style={{ width: tempWidth - tempWidth*0.10, height: tempHeight - tempHeight*0.20}}
                    >
                    </div>
                </div>

            </div>
        </Fragment>
    );
}