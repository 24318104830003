import React,{ useState} from 'react';
import './style.scss';
import {Alert} from 'react-bootstrap';
import { useDispatch } from "react-redux";
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import {signupRequest} from '../../actions/auth';
import store from '../../store';
import logoSamll from './../../assets/guruji-logo-new.png';
import GoogleLoginButton from './../../components/google-button';


function SignupPage(){
  let state = store.getState();
  const dispatch = useDispatch();
  const [errors, set_errors] = useState({
    username: '',
    password: '',
    confirmpassword: '',
    fullname: '',
    mobile: '',

    error: ''
  })

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [fullname, setFullname] = useState("");
  const [mobile, setMobile] = useState("");
  const [accountType] = useState(true);
  const [isdisabled] = useState(false);


  

  function handleSignup(){
      if (fullname === ""){
        set_errors({
          fullname: 'Full Name Required!'
        })
      }else if (fullname.length>0 && fullname.length<=3){
        set_errors({
          fullname: 'Full Name must contain 4 characters or more characters'
        })
      }else if (email === '') {
        set_errors({
          username: 'Email Required!'
        })
      }else if (!email_regex.test(email)){
        set_errors({
          username: 'Enter Valid Email Address !'
        })
      }else if (password === ''){
        set_errors({
          password: 'Password Required!'
        })
      }else if (password.length < 6){
        set_errors({
          password: 'Password must be grater then 6 characters.'
        })
      }else if (confirmpassword !== password){
        console.log(confirmpassword !== password, confirmpassword, password);
        set_errors({
          confirmpassword: 'Password Does Not Match!'
        })
      }else if (mobile === ''){
        set_errors({
          mobile: 'Mobile Required!'
        })
      }
      if (errors.fullname === '' && errors.username === '' && errors.password === '' && errors.mobile === ''){
        const user = {
          email,
          password,
          fullname,
          mobile,
          accountType,
        }
        dispatch(signupRequest(user))
        console.log(state);
      }
  }
  function handleChange(event){

    if (event.target.name==="email"){
      setEmail(event.target.value)
    }

    if (event.target.name==="password"){
      setPassword(event.target.value)
    }

    if (event.target.name==="confirmpassword"){
      setConfirmpassword(event.target.value)
    }

    if (event.target.name==="fullname"){
      setFullname(event.target.value)
    }

  }
  const email_regex = /^[-!#$%&'*+0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  return (
    <div className="signup-page">
      <div className="signup-container">
        <a href="/" className="logo-image">
          <img src={logoSamll} alt="" />
        </a>
        <h1>Create Your Account</h1>
        <input type="hidden" value="something"/>
        <div className="input">
          <input autoComplete="off" type="text" name="fullname" placeholder="Full Name"  onChange={handleChange} />
          
          {errors.fullname && <Alert key="fullname" style={{marginTop: '5px'}} variant="danger">
            {errors.fullname}
          </Alert>}
        </div>
        <div className="input">
          <input autoComplete="off" type="email" name="email" placeholder="Email" onChange={handleChange} />
          
          {errors.username && <Alert key="username" style={{marginTop: '5px'}} variant="danger">
            {errors.username}
          </Alert>}
        </div>
        <div className="input">
          <input type="password" name="password" placeholder="Password" autoComplete="off" onChange={handleChange} />
          
          {errors.password && <Alert key="password" style={{marginTop: '5px'}} variant="danger">
            {errors.password}
          </Alert>}
        </div>
        <div className="input">
          <input type="password" name="confirmpassword" placeholder="Confirm Password" autoComplete="off" onChange={handleChange} />
          
          {errors.confirmpassword && <Alert key="confirmpassword" style={{marginTop: '5px'}} variant="danger">
            {errors.confirmpassword}
          </Alert>}
        </div>
        <div className="input">
          <MaterialUiPhoneNumber
          className="mobile-mumber"
          defaultCountry="in"
          autoComplete="off"
          disableDropdown={true}
          onChange={(e) => {
            setMobile(e)
          }}
          />
          {errors.mobile && <Alert key="mobile" style={{marginTop: '5px'}} variant="danger">
            {errors.mobile}
          </Alert>}
          {state.auth.error && <Alert key="error" style={{marginTop: '5px'}} variant="danger">
          {state.auth.error}
          </Alert>}
        </div>
        <div className="login-button">
          <button className="btn btn-primary" onClick={handleSignup} disabled={isdisabled}>Sign Up</button>
        </div>
        <div className="google-login-button" style={{textAlign: 'center'}}>
          <h1>Or</h1>
          <GoogleLoginButton />
        </div>
      </div>
    </div>
  );
}


export default SignupPage;
