import React from 'react';
import './style.scss';
import RefundPDF from './../../assets/REFUND-POLICY.pdf';
import AllPages from './../../components/pdf/all-pages';

function GlobalRefundPolicy ({height}) {

  return (
    <div id="terms" style={{height: height+"px"}}>
      <AllPages pdf={RefundPDF} />
    </div>
  )
}

export default GlobalRefundPolicy ;
