import React, { Fragment } from "react";
import DefaultCardImage from './../../../assets/default-course-card-img.png';

interface MyCoursesProps {
    courses: any;
}

const MyCourses = (props: MyCoursesProps) => {
    return (
        <Fragment>
            {props.courses && props.courses.map((course, i) => <div key={"c_" + course.key} className="course">
                <a href={"/course/" + course.key}>
                    <div className="cimage" style={{ background: "url(" + (course.card_image || DefaultCardImage) + ")" }}></div>
                </a>
                <div className="ccontent">
                    <h1 style={{ minHeight: '44px' }}>{course.title}</h1>
                    <p className="cgoal">{course.goal}</p>
                    <div className="cta-btn">
                        <a className="btn btn-primary buy-now" href={"/course/" + course.key}>{course.admin ? "Edit" : "View"}</a>
                    </div>
                </div>
            </div>)}
        </Fragment>
    );
}

export default MyCourses;