const developmentEnv = "dev"

let BASE_URL;
if (developmentEnv==="prod"){
  BASE_URL = "https://api.learnwithguruji.com"
}else{
  BASE_URL = "http://localhost:5000";
}

export {BASE_URL}
