import React, { Fragment, useEffect } from "react";
import { Button } from "./button";
import { Div } from './div';
import { Heading } from './heading';


export const Toolbar = () => {

    useEffect(() => {

    }, []);
    const dragStart = (event) => {
        event.dataTransfer.setData("text/plain", 'hello')
    }


    return (
        <Fragment>
            <div
                onDragStart={dragStart}

                draggable={true}
                id={`toolbar`}
            >

                <Button id='button' text='button' styles={{}} />
                <Div id='div' text='div' styles={{}} />
                <Heading id='heading' text='heading' styles={{}} />


            </div>
        </Fragment>
    );
}