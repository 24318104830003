import React, { useState, useEffect, Fragment} from 'react';
import './style.scss';
import { useSelector, useDispatch} from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import {
  getQuiz
} from './../../actions/quiz';
import { BASE_URL } from './../../config';
import store from '../../store';
import LoginRequired from '../../components/models/login-required';
import CustomVideoPlayer from '../../components/video-player';
import EditQuiz from '../../components/edit-quiz';

function QuizOverModel({ show, handleClose, score, setQuestionIndex, set_score }) {

  return (
    <>
      <Modal show={show}>
        <Modal.Header closeButton>
          <Modal.Title>Quiz Finished</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Congratulation you have successfully completed the quiz.</p>
          <p>Your score is {score}.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => {
            setQuestionIndex(0);
            set_score(0);
            handleClose()
          }}>
            Start Again
          </Button>
          <Button variant="secondary" onClick={() => {
            document.location = "/dashboard";
          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function TryAgain({ show, setShow }) {
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Try Taking Quiz Again!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your score reached zero, try again!</p>
          <p>for every correct answer <strong>+1</strong> and for every wrong answer <strong>-1</strong> score will be given.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function RenderQuizOptions({ quiz_id, question_id, setQuestionIndex, questionIndex, options, answer, nextQuestion, score, set_score }) {

  const [tryAgainModel, set_tryAgainModel] = useState(false);

  function incScore() {
    set_score(score + 1);
  }

  function decScore() {
    if (score === 0) {
      setTimeout(() => {
        set_tryAgainModel(true);
        setQuestionIndex(0);
      }, 1300)
    } else {
      set_score(score - 1);
    }
  }

  function handleTryAgainModelShow(status) {
    set_tryAgainModel(status)
  }

  function createSubmission(question_id, quiz_id, answer) {
    let token = localStorage.getItem('token') || null;

    fetch(`${BASE_URL}/quiz/question/submission`, {
      method: 'POST',
      body: JSON.stringify({
        quiz_id: parseInt(quiz_id),
        question_id: parseInt(question_id),
        answer: answer
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token,
      }
    }).then(response => response.json())
      .then((data) => {

      })
  }

  function handleSubmission(e, opt) {
    let target_ele = e.target.id;
    if (opt.id === answer) {
      document.getElementById(e.target.id).style.backgroundColor = "green";
      let delay = null;
      incScore();
      delay = setTimeout(() => {
        nextQuestion();
        clearTimeout(delay)
      }, 1000);
    } else {
      document.getElementById(e.target.id).style.backgroundColor = "red";
      decScore();
      let delay = setTimeout(() => {
        document.getElementById(target_ele).style.backgroundColor = "gray";
        clearTimeout(delay)
      }, 1000);
    }

    createSubmission(question_id, quiz_id, opt.id);
  }

  return (
    <>
      <TryAgain show={tryAgainModel} setShow={handleTryAgainModelShow} />
      {options.map((opt, i) => {
        return (
          <div key={"quiz_option_" + opt.id} id={"quiz_option_" + opt.id} className="question-option" onClick={(e) => handleSubmission(e, opt)}>
            {opt.value}
          </div>
        )
      })}
    </>
  )
}

function QuizPage({ match }) {
  const state = store.getState();

  const dispatch = useDispatch();

  const isAuthenticated = useSelector(state => {
    return state.auth.isAuthenticated
  })

  

  

  const [score, set_score] = useState(0);

  const all_questions = useSelector((state) => {
    return state.quiz.quiz.questions
  })

  const [questionIndex, setQuestionIndex] = useState(0);

  

  const [quiz_over, set_quiz_over] = useState(false);

  function handleQuizOverClose() {
    set_quiz_over(false);
  }
  useEffect(() => {
    dispatch(getQuiz(match.params.quiz_id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch])

  function nextQuestion() {
    state.quiz.quiz.questions[questionIndex].options.forEach((e, i) => {
      document.getElementById("quiz_option_" + e.id).style.backgroundColor = "gray";
    })

    if (questionIndex > all_questions.length - 2) {
      set_quiz_over(true);
    } else {
      setQuestionIndex(questionIndex + 1);
    }
  }

  return (
    <>

      <LoginRequired />

      {isAuthenticated && <QuizOverModel setQuestionIndex={setQuestionIndex} show={quiz_over} handleClose={handleQuizOverClose} score={score} set_score={set_score} />}
      {isAuthenticated && !quiz_over && state.quiz.quiz && state.quiz.quiz.questions && <div className="container-fluid quiz-container">
        {all_questions.length > 0 && <Fragment>
          <div className="row">
            <div className="col-md-12 total-questions">
              <p>My Score : {score}</p>
              <p>{questionIndex + 1}/{all_questions.length}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 question-title">
              <h1>{state.quiz.quiz.questions[questionIndex] ? state.quiz.quiz.questions[questionIndex].title : ""}</h1>
            </div>
            <div className="col-md-12">
              <div className="question-body">
                <p>{state.quiz.quiz.questions[questionIndex] ? state.quiz.quiz.questions[questionIndex].body : ""}</p>
              </div>
            </div>
            <div className="col-md-12 question-options">
              {state.quiz.quiz.questions[questionIndex] ? (
                <RenderQuizOptions quiz_id={state.quiz.quiz.quiz.id} question_id={state.quiz.quiz.questions[questionIndex].id} setQuestionIndex={setQuestionIndex} questionIndex={questionIndex} score={score} set_score={set_score} quiz_Id={match.params.quiz_id} nextQuestion={nextQuestion} answer={state.quiz.quiz.questions[questionIndex].answer} options={state.quiz.quiz.questions[questionIndex].options} />
              ) : (<></>)}
            </div>
            {state.quiz.quiz.questions[questionIndex] && state.quiz.quiz.questions[questionIndex].video_explanation && <div className="video-explainer">
              <CustomVideoPlayer src={state.quiz.quiz.questions[questionIndex].video_explanation} />
            </div>}
            {state.quiz.quiz.questions[questionIndex] && state.quiz.quiz.questions[questionIndex].explanation && <div className="explanation">
              {state.quiz.quiz.questions[questionIndex].explanation}
            </div>}
          </div>
          {state.quiz.quiz.is_admin && <div className="row">
            <div className="col-md-12">
              <EditQuiz quiz={state.quiz.quiz} question_index={questionIndex} />
            </div>
          </div>}
        </Fragment>}
        {all_questions.length === 0 && <div style={{color: 'white'}}>Quiz is Empty!</div>}
      </div>}
    </>
  )
}

export default QuizPage
