import React, { Fragment } from "react";

export const Div = ({ id, text, styles }) => {
    return (
        <Fragment>
            <div
                id={id}
                style={styles}
            >{text}</div>
        </Fragment>
    );
}