import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer';
import NavbarGlobal from '../../components/navbar';
import { useSelector, useDispatch } from 'react-redux';

import './style.scss';

import {
    getAllCourses
} from './../../actions/course';
 


function InstructorProfile({props}){

    const dispatch = useDispatch();
   
    const [isPublic, set_isPublic] = useState(false);

    useEffect(()=>{
        let token = localStorage.getItem('token') || null;
        if (token){
            dispatch(getAllCourses())
            
        }else{
            dispatch(getAllCourses({type: "public"}))
            set_isPublic(true);
            
        }
    }, [dispatch])
    const user = useSelector(state => {
        return state.user.user
    })

    const courses = useSelector(state => {
       if (!isPublic){
        return state.course.courses.courses
       } else {
        return state.course.courses
       } 
    })

    return (
        <div id="instructor-profile" className="container-fluid">
            <NavbarGlobal/>
            <div className="row instr-banner">
                <div className="col-md-12 info">
                    <div className="">
                        <h1>I am, {user.first_name} {user.last_name}</h1>
                        <p>Instructor Partner at leanwithguruji.com</p>
                    </div>
                </div>
                
            </div>
            <div className="row">
                <div className="col-md-12">
                        <div className="my-courses">
                                {courses && courses.filter(c=>c.published).map((c, i)=><div className="instr-course-card">
                                <div className="instr-course-image" style={{backgroundImage: "url("+c.card_image+")"}}>
                                        <a href={"/course/view/"+c.key}>
                                            <div className="bg-blur">
                                                <div className="course-title">
                                                    <h1>{c.title}</h1>
                                                </div>
                                            </div>
                                        </a>
                                        
                                    </div>
                                </div>)}
                        </div>                    
                    </div>
            </div>
            <Footer/>
        </div>
    )
}

export default InstructorProfile