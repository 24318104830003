import {
  GET_ALL_COURSE_REQUEST,
  GET_ALL_COURSE_SUCCESS,
  GET_ALL_COURSE_ERROR,
  GET_ALL_FEATURED_COURSE_REQUEST,
  GET_ALL_FEATURED_COURSE_SUCCESS,
  GET_ALL_FEATURED_COURSE_ERROR,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_ERROR,
  CREATE_COURSE_APPLY_REQUEST,
  CREATE_COURSE_APPLY_SUCCESS,
  CREATE_COURSE_APPLY_ERROR,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_ERROR,
  COURSE_FAILED,
  REQUEST_COURSE,
  RECEIVE_COURSE,
  CHAPTER_GET_ALL,
  CHAPTER_CREATED_FAILED,
  CHAPTER_LOAD_CONTENT,
  CHAPTER_CREATED_SUCCESS,
  CHAPTER_REMOVED_ERROR,
  CHAPTER_REMOVED_SUCCESS,
  CHAPTER_VIDEO_ADDED_ERROR,
  CHAPTER_VIDEO_ADDED_SUCCESS,
  CHAPTER_VIDEO_REMOVE_ERROR,
  CHAPTER_VIDEO_REMOVE_SUCCESS,
  CREATE_COURSE_PAYMENT_REQUEST,
  CREATE_COURSE_PAYMENT_SUCCESS,
  CREATE_COURSE_PAYMENT_ERROR,
  
  COURSE_PREVIEW_SUCCESS,
  COURSE_PREVIEW_PAGE_SUCCESS
} from './../actions/course'

const defaultState = {
  course: {
    chapters: []
  },
  courses: ''
}

const course = (state = defaultState, action) => {
  
  switch (action.type){
    case COURSE_PREVIEW_SUCCESS:
      state.course.admin = false;
      return {...state}

    case COURSE_PREVIEW_PAGE_SUCCESS:
      state.course.admin = false;
      state.course.applied = false;
      state.course.has_subscription = false;
      return {...state}

    case GET_ALL_COURSE_REQUEST:
      return Object.assign({}, state, {
        courses: action.courses,
      })
    case GET_ALL_COURSE_SUCCESS:
      return Object.assign({}, state, {
        courses: action.courses,
      })
    case GET_ALL_COURSE_ERROR:
      return state
    case CREATE_COURSE_PAYMENT_REQUEST:
      return state
    case CREATE_COURSE_PAYMENT_SUCCESS:
      return state
    case CREATE_COURSE_PAYMENT_ERROR:
      return state
    case CREATE_COURSE_REQUEST:
      return state
    case CREATE_COURSE_SUCCESS:
      return state
    case CREATE_COURSE_ERROR:
      return state
    case GET_ALL_FEATURED_COURSE_REQUEST:
      return state
    case GET_ALL_FEATURED_COURSE_SUCCESS:
      return Object.assign({}, state, {
        featured: action.featured
      })
    case GET_ALL_FEATURED_COURSE_ERROR:
      return state
    case CREATE_COURSE_APPLY_REQUEST:
      return state
    case CREATE_COURSE_APPLY_SUCCESS:
      return state
    case CREATE_COURSE_APPLY_ERROR:
      return state
    case UPDATE_COURSE_REQUEST:
      return state
    case UPDATE_COURSE_SUCCESS:
      return state
    case UPDATE_COURSE_ERROR:
      return state
    case REQUEST_COURSE:
      return state
    case COURSE_FAILED:
      return Object.assign({}, state, {
        course: '',
        mychapter: ''
      })
    case RECEIVE_COURSE:
      let mychapter = [{}];
      if (action.course.chapters){
        mychapter = action.course.chapters;
        let corder_arr = mychapter.map((c)=>c.corder).sort()
        mychapter = mychapter.filter((chapter)=>chapter.corder === corder_arr[0]);
      }
      state.course = action.course
      state.mychapter = mychapter[0]
      return {...state}

    case CHAPTER_CREATED_SUCCESS:
      if (state.course.chapters){
        state.course.chapters.push(action.chapter)
      }else{
        state.course = {}
        state.course.chapters = [];
        state.course.chapters.push(action.chapter)
      }
      return {...state}

    case CHAPTER_CREATED_FAILED:

      return {...state}

    case CHAPTER_GET_ALL:
      let newStage = state;
      newStage.chapters = action.chapters
      return {...newStage}

    case CHAPTER_LOAD_CONTENT:
      return Object.assign({}, state, {
        mychapter: action.chapter
      })

    case CHAPTER_REMOVED_SUCCESS:
      let chapters = state.course.chapters.filter((c)=>c.id!==action.chapter_id)
      state.course.chapters = chapters;
      return Object.assign({}, state, {
        mychapter: '',
        message: 'chapter removed'
      })
    case CHAPTER_REMOVED_ERROR:
      return Object.assign({}, state, {
        error: 'unbale to remove chapter'
      })
    case CHAPTER_VIDEO_ADDED_SUCCESS:
      for (var i in state.course.chapters){
        if (state.course.chapters[i].id === state.mychapter.id){
          state.course.chapters[i].videos.push(action.video)
          state.mychapter = state.course.chapters[i]
        }
      }
      return Object.assign({}, state, {
        message: 'chapter video added success'
      })
    case CHAPTER_VIDEO_ADDED_ERROR:
      return Object.assign({}, state, {
        error: action.error
      })
    case CHAPTER_VIDEO_REMOVE_SUCCESS:
      let newVideos = state.mychapter.videos.filter((v)=>v.id!==action.video_id);
      state.mychapter.videos = newVideos;
      let course_videos = state.course.chapters[state.mychapter.id].videos.filter((v)=>v.id!==action.video_id);
      state.course.chapters[state.mychapter.id].videos = course_videos;

      return Object.assign({}, state, {
        message: 'chapter video removed'
      })
    case CHAPTER_VIDEO_REMOVE_ERROR:
      return Object.assign({}, state, {
        error: 'unbale to remove chapter video'
      })


    default:
      return state
  }
}


export default course
