import React from 'react';
import './style.scss';

function Footer () {
    const currentDate = new Date();

    return (
      <div className="row" id="footer-section">
        <footer className="col-md-12">
          <div className="row">
            <div className="col-md-4 f-section">
              <a href="mailto:support@learnwithguruji.com">support@learnwithguruji.com</a>
            </div>
            <div className="col-md-12">
              <ul className="inline">
                <li><a href="/terms">Terms &amp; Condition</a></li>
                <li><a href="/privacy">Privacy Policy</a></li>
                <li><a href="/refund">Refund Policy</a></li>
              </ul>
            </div>
            <div className="col-md-12 footer-copy-line">
              &copy; {currentDate.getFullYear()} learnwithguruji.com All rights reserved.
            </div>
          </div>
        </footer>
      </div>
    );
}

export default Footer;
