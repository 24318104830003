import React from 'react';
import './style.scss';
import PrivacyPDF from './../../assets/privacy-policy.pdf';
import AllPages from './../../components/pdf/all-pages';

function GlobalPrivacy ({height}) {

  return (
    <div id="terms" style={{height: height+"px"}}>
      <AllPages pdf={PrivacyPDF} />
    </div>
  )
}

export default GlobalPrivacy;
