import React from 'react';
import {  useDispatch } from "react-redux";
import GoogleLogin from 'react-google-login';

import {
  login
  
} from './../../actions/auth';
function GoogleLoginButton () {
  const dispatch = useDispatch();

  const responseGoogle = (response) => {
    try {
      console.log(response);
      const user = {
        accessToken: response.accessToken,
        username: response.profileObj.email,
        password: undefined,
        fullname: response.profileObj.name,
        social: true
      };
      dispatch(login(user));
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <GoogleLogin
      clientId="783773059247-csdvhgbb9e1qaik071k25f5fis3tehbl.apps.googleusercontent.com"
      buttonText="Login Using Google"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={'single_host_origin'}
    />
  )
}


export default GoogleLoginButton;
