import React, {useState, useEffect} from 'react';
import useSound from 'use-sound';
import './styles.scss';
import {Button} from 'react-bootstrap';
import NavbarGlobal from './../../components/navbar';

import aImg from './../../assets/playground/learn-abcd/a.jpg';
import bImg from './../../assets/playground/learn-abcd/b.jpg';
import cImg from './../../assets/playground/learn-abcd/c.jpg';
import dImg from './../../assets/playground/learn-abcd/d.jpg';
import eImg from './../../assets/playground/learn-abcd/e.jpg';
import fImg from './../../assets/playground/learn-abcd/f.jpg';
import gImg from './../../assets/playground/learn-abcd/g.jpg';
import hImg from './../../assets/playground/learn-abcd/h.jpg';
import iImg from './../../assets/playground/learn-abcd/i.jpg';
import jImg from './../../assets/playground/learn-abcd/j.jpg';
import kImg from './../../assets/playground/learn-abcd/k.jpg';
import lImg from './../../assets/playground/learn-abcd/l.jpg';
import mImg from './../../assets/playground/learn-abcd/m.jpg';
import nImg from './../../assets/playground/learn-abcd/n.jpg';
import oImg from './../../assets/playground/learn-abcd/o.jpg';
import pImg from './../../assets/playground/learn-abcd/p.jpg';
import qImg from './../../assets/playground/learn-abcd/q.jpg';
import rImg from './../../assets/playground/learn-abcd/r.jpg';
import sImg from './../../assets/playground/learn-abcd/s.jpg';
import tImg from './../../assets/playground/learn-abcd/t.jpg';
import uImg from './../../assets/playground/learn-abcd/u.jpg';
import vImg from './../../assets/playground/learn-abcd/v.jpg';
import wImg from './../../assets/playground/learn-abcd/w.jpg';
import xImg from './../../assets/playground/learn-abcd/x.jpg';
import yImg from './../../assets/playground/learn-abcd/y.jpg';
import zImg from './../../assets/playground/learn-abcd/z.jpg';


import aSound from './../../assets/playground/learn-abcd/sounds/a.ogg';
import bSound from './../../assets/playground/learn-abcd/sounds/b.ogg';
import cSound from './../../assets/playground/learn-abcd/sounds/c.ogg';
import dSound from './../../assets/playground/learn-abcd/sounds/d.ogg';
import eSound from './../../assets/playground/learn-abcd/sounds/e.ogg';
import fSound from './../../assets/playground/learn-abcd/sounds/f.ogg';
import gSound from './../../assets/playground/learn-abcd/sounds/g.ogg';
import hSound from './../../assets/playground/learn-abcd/sounds/h.ogg';
import iSound from './../../assets/playground/learn-abcd/sounds/i.ogg';
import jSound from './../../assets/playground/learn-abcd/sounds/j.ogg';
import kSound from './../../assets/playground/learn-abcd/sounds/k.ogg';
import lSound from './../../assets/playground/learn-abcd/sounds/l.ogg';
import mSound from './../../assets/playground/learn-abcd/sounds/m.ogg';
import nSound from './../../assets/playground/learn-abcd/sounds/n.ogg';
import oSound from './../../assets/playground/learn-abcd/sounds/o.ogg';
import pSound from './../../assets/playground/learn-abcd/sounds/p.ogg';
import qSound from './../../assets/playground/learn-abcd/sounds/q.ogg';
import rSound from './../../assets/playground/learn-abcd/sounds/r.ogg';
import sSound from './../../assets/playground/learn-abcd/sounds/s.ogg';
import tSound from './../../assets/playground/learn-abcd/sounds/t.ogg';
import uSound from './../../assets/playground/learn-abcd/sounds/u.ogg';
import vSound from './../../assets/playground/learn-abcd/sounds/v.ogg';
import wSound from './../../assets/playground/learn-abcd/sounds/w.ogg';
import xSound from './../../assets/playground/learn-abcd/sounds/x.ogg';
import ySound from './../../assets/playground/learn-abcd/sounds/y.ogg';
import zSound from './../../assets/playground/learn-abcd/sounds/z.ogg';

function PlaygroundGameLearnABCD (){
    const [alphabetImg, set_alphabetImg] = useState(aImg);
    const [aPlay] = useSound(aSound);
    const [bPlay] = useSound(bSound);
    const [cPlay] = useSound(cSound);
    const [dPlay] = useSound(dSound);
    const [ePlay] = useSound(eSound);
    const [fPlay] = useSound(fSound);
    const [gPlay] = useSound(gSound);
    const [hPlay] = useSound(hSound);
    const [iPlay] = useSound(iSound);
    const [jPlay] = useSound(jSound);
    const [kPlay] = useSound(kSound);
    const [lPlay] = useSound(lSound);
    const [mPlay] = useSound(mSound);
    const [nPlay] = useSound(nSound);
    const [oPlay] = useSound(oSound);
    const [pPlay] = useSound(pSound);
    const [qPlay] = useSound(qSound);
    const [rPlay] = useSound(rSound);
    const [sPlay] = useSound(sSound);
    const [tPlay] = useSound(tSound);
    const [uPlay] = useSound(uSound);
    const [vPlay] = useSound(vSound);
    const [wPlay] = useSound(wSound);
    const [xPlay] = useSound(xSound);
    const [yPlay] = useSound(ySound);
    const [zPlay] = useSound(zSound);

    const [alphabets] = useState([
      "a", "b", "c", "d",
      "e", "f", "g", "h", "i",
      "j", "k", "l", "m", "n", "o",
      "p", "q", "r", "s", "t", "u",
      "v", "w", "x", "y", "z"
    ])



    useEffect(()=>{

    }, [])

    function playSound(letter){
      if (letter === "a"){
        set_alphabetImg(aImg);
        aPlay();
      }else if(letter === "b"){
        set_alphabetImg(bImg);

        bPlay();
      }else if(letter === "c"){
        set_alphabetImg(cImg);

        cPlay();
      }else if(letter === "d"){
        set_alphabetImg(dImg);

        dPlay();
      }else if(letter === "e"){
        set_alphabetImg(eImg);

        ePlay();
      }else if(letter === "f"){
        set_alphabetImg(fImg);

        fPlay();
      }else if(letter === "g"){
        set_alphabetImg(gImg);

        gPlay();
      }else if(letter === "h"){
        set_alphabetImg(hImg);

        hPlay();
      }else if(letter === "i"){
        set_alphabetImg(iImg);

        iPlay();
      }else if(letter === "j"){
        set_alphabetImg(jImg);

        jPlay();
      }else if(letter === "k"){
        set_alphabetImg(kImg);

        kPlay();
      }else if(letter === "l"){
        set_alphabetImg(lImg);

        lPlay();
      }else if(letter === "m"){
        set_alphabetImg(mImg);

        mPlay();
      }else if(letter === "n"){
        set_alphabetImg(nImg);

        nPlay();
      }else if(letter === "o"){
        set_alphabetImg(oImg);

        oPlay();
      }else if(letter === "p"){
        set_alphabetImg(pImg);

        pPlay();
      }else if(letter === "q"){
        set_alphabetImg(qImg);

        qPlay();
      }else if(letter === "r"){
        set_alphabetImg(rImg);

        rPlay();
      }else if(letter === "s"){
        set_alphabetImg(sImg);

        sPlay();
      }else if(letter === "t"){
        set_alphabetImg(tImg);

        tPlay();
      }else if(letter === "u"){
        set_alphabetImg(uImg);

        uPlay();
      }else if(letter === "v"){
        set_alphabetImg(vImg);

        vPlay();
      }else if(letter === "w"){
        set_alphabetImg(wImg);

        wPlay();
      }else if(letter === "x"){
        set_alphabetImg(xImg);

        xPlay();
      }else if(letter === "y"){
        set_alphabetImg(yImg);

        yPlay();
      }else if(letter === "z"){
        set_alphabetImg(zImg);

        zPlay();
      }else {

      }
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <NavbarGlobal />
          <div className="col-md-12 cimage">
            <div className="image-alphabets">
              <img src={alphabetImg} alt="Alphabets" />
            </div>
          </div>
          <div className="col-md-12 options">
            {alphabets.map(letter=><Button key={"key_"+letter} variant="primary" onClick={()=>playSound(letter)}>{letter}</Button>)}
          </div>
        </div>
      </div>
    )
}


export default PlaygroundGameLearnABCD;
