import React, { useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { BASE_URL } from './../../config';

import {
    getAllQuizzes
} from './../../actions/quiz';

function DeleteQuizModel({ show, handleShow, handleClose, quiz_id }) {

    const dispatch = useDispatch();

    useEffect(() => {

    }, [show, handleShow, handleClose, quiz_id])

    function handleDelete() {
        let token = localStorage.getItem('token') || null;

        fetch(`${BASE_URL}/quiz/${quiz_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(response => response.json())
            .then((data) => {
                handleClose();
                dispatch(getAllQuizzes());
            })
    }

    return (
        <Modal style={{ zIndex: 1000000 }} show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Quiz</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    Are you sure you want to delete this Quiz?
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default DeleteQuizModel;
