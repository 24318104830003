import React, { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import './styles.scss';
const FullPageLoading = () => <Fragment>
    <div id="full-page-loading">
        <div className="loading-img">
            <Spinner animation="border" variant="light" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    </div>
</Fragment>;

export default FullPageLoading;