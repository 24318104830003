import { BASE_URL } from './../config';
import axios from 'axios';

const upload = (event, progress_callback, final_callback) => {

  let file = event.target.files[0];
  let fileName = Math.random().toString(36).substring(7)+"-"+event.target.files[0].name;
  let fileType = event.target.files[0].type;

  axios.post(`${BASE_URL}/upload/s3signurl`, {
    filename: fileName,
    filetype: fileType
  })
  .then((response)=>{


    axios.put(response.data.url,file,{
      "headers": {
        "content-type": undefined,
      },
      onUploadProgress: function(progressEvent) {
         let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
         progress_callback(percentCompleted)
     }
    })
    .then((response)=>{
      axios.get(`${BASE_URL}/upload/s3signurl?file=`+fileName)
      .then((response)=>{
        final_callback(response.data)
      })
    })
  })
}

export default upload;
