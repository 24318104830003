import React, { Fragment } from "react";

export const Button = ({ id, text, styles }) => {
    const dragStart = (event) => {
        event.dataTransfer.setData("text/plain", 'button')
    }
    return (
        <Fragment>
            <button
                onDragStart={dragStart}
                draggable={true}
                id={id}
                style={styles}
            >{text}</button>
        </Fragment>
    );
}