import React, {useEffect} from 'react';
import {  useDispatch } from 'react-redux';
import {
  logoutUser
} from './../../actions/auth';

function LogoutPage(){
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(logoutUser())
  }, [dispatch])
  return (
    <div>Please Wait ...</div>
  )
}


export default LogoutPage;
