import React from 'react';
import { Fragment, useState } from 'react';
import './style.scss';

const SelectUserType = () => {
    const [accountType] = useState([
        {
            name: "Institute"
        },
        {
            name: "Company"
        },
        {
            name: "Startup"
        },
        {
            name: "Learner"
        }
    ])
    return (
        <Fragment>
            <div className="wrapper">
                <h1>I am</h1>
                <div className="select-user-type">
                    {accountType.map((at) => <div key={at.name} className="account-type">{at.name}</div>)}
                </div>
            </div>

        </Fragment>
    )
};

export default SelectUserType;