import React, { useState, Fragment } from "react";
import { Form, Button} from "react-bootstrap";
import "./style.scss";
import NavbarGlobal from "../../components/navbar";

function ContactPage(){
    const [name] = useState("")
    const [company] = useState("")
    const [email] = useState("")
    const [message] = useState("")
    const [contactNo] = useState("")

    function handleContact(){
        var formData = new FormData();

        formData.append('entry.1109539144', encodeURIComponent(name));
        formData.append('entry.1419487821', encodeURIComponent(email));
        formData.append('entry.1214836721', encodeURIComponent(company));
        formData.append('entry.842004132', encodeURIComponent(message));
        formData.append('entry.914153587', encodeURIComponent(contactNo));

        const queryString = new URLSearchParams(formData).toString()

        

        //entry.1109539144=Surya&entry.1419487821=sing.shekhawat67%40gmail.com&entry.1214836721=learnwithguruji&entry.842004132=hi&entry.914153587=9945551067

        fetch("https://docs.google.com/forms/u/2/d/e/1FAIpQLSdVEeHtbGEIAF8-O_9oizohxcdxVe6QUybwMX__V3izCPDRCA/formResponse", {
            "headers": {
                "accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
                "accept-language": "en-GB,en-US;q=0.9,en;q=0.8",
                "cache-control": "max-age=0",
                "content-type": "application/x-www-form-urlencoded",
                // "sec-ch-ua": "\" Not;A Brand\";v=\"99\", \"Google Chrome\";v=\"91\", \"Chromium\";v=\"91\"",
                // "sec-ch-ua-mobile": "?0",
                // "sec-fetch-dest": "document",
                // "sec-fetch-mode": "navigate",
                // "sec-fetch-site": "same-origin",
                // "sec-fetch-user": "?1",
                // "upgrade-insecure-requests": "1",
                // "x-chrome-connected": "source=Chrome,id=113404824724486462491,mode=0,enable_account_consistency=false,supervised=false,consistency_enabled_by_default=false",
                // "x-client-data": "CKy1yQEIk7bJAQiltskBCKmdygEI5eXKAQigoMsBCIXyywEIrPLLAQjc8ssBCPDyywEIs/jLAQie+csBCKz5ywEI9vnLARiOnssBGLryywEYj/XLARje+csB"
            },
            // "referrer": "https://docs.google.com/forms/d/e/1FAIpQLSdVEeHtbGEIAF8-O_9oizohxcdxVe6QUybwMX__V3izCPDRCA/viewform?fbzx=-3019187738554830979",
            // "referrerPolicy": "strict-origin-when-cross-origin",
            "body": queryString+"&fvv=1&draftResponse=%5Bnull%2Cnull%2C%22-3019187738554830979%22%5D%0D%0A&pageHistory=0&fbzx=-3019187738554830979",
            "method": "POST",
            // "mode": "cors",
            // "credentials": "include"
            });
    }

    return (
        <Fragment>
        <NavbarGlobal />
        <div className="container contact-container">
            <h4>Contact Our Support Team</h4>
            <div class="row">
                <div class="offset-md-4 col-md-4 offset-md-4 col-xs-12">
                    <Form>
                        <Form.Group controlId="frmFullName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">
                                Enter your Fullname.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="frmEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="" />
                            <Form.Text className="text-muted">
                                Enter your Email.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="frmCompany">
                            <Form.Label>Company</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">
                                Enter your Company Name.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="frmMessage">
                            <Form.Label>Message</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">
                                Enter your Message.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="frmContactno">
                            <Form.Label>Contact No</Form.Label>
                            <Form.Control type="text" placeholder="" />
                            <Form.Text className="text-muted">
                                Enter your Contact No.
                            </Form.Text>
                        </Form.Group>
                        <Button variant="primary" type="button" onClick={()=>handleContact()}>
                            Send Ticket
                        </Button>
                    </Form>
                </div>
                
            </div>
        </div>
        </Fragment>
    )
}

export default ContactPage;