import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';
import './styles.scss'
function CustomVideoPlayer({src}){
  const [videoType, set_videoType] = useState("default");

  useEffect(()=>{
    if (src){
      if (src.indexOf('https://www.youtube.com') >= 0){
        set_videoType("youtube")
      }else if (src.indexOf('https://player.vimeo.com') >= 0){
        set_videoType("vimeo")
      }else{
        set_videoType("default")
      }
    }
  }, [src])

  return (
    <div className="custom-video-player">
      {videoType === "vimeo" && <iframe width="100%" height="100%" src={src} title="vimeo video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>}
      {videoType === "youtube" && <iframe width="100%" height="100%" src={src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>}
      {videoType === "default" && <ReactPlayer volume={0.8} controls={true} url={src} /> }
    </div>
  )
}

export default CustomVideoPlayer;
