import React, { Fragment, useState, useEffect } from 'react';
import CodeEditor from '../../components/ace-editor';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../../config';
const WebsiteEditor = () => {
    const [htmlBody, setHtmlBody] = useState("");
    const [cssStyle, setCssStyle] = useState("");
    const [jsScript, setJsScript] = useState("");
    const [templateUrl, setTemplateUrl] = useState("");

    useEffect(() => {
        getUserTemplate();
    }, [])

    const getUserTemplate = () => {
        let token = localStorage.getItem('token') || null;
        axios.get(`${BASE_URL}/template`, {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then((resp) => {
            console.log(resp.data);
            setHtmlBody(resp.data.result.templates[0].data.html_body);
            setCssStyle(resp.data.result.templates[0].data.css_body);
            setJsScript(resp.data.result.templates[0].data.js_body);
            setTemplateUrl(`https://cdn.learnwithguruji.com/apps/${resp.data.result.app.name}/template/index.html?time=${new Date().getTime()}`);
        }).catch((err) => {
            console.log(err);
        })
    }
    const handleHtmlChange = (data) => {
        setHtmlBody(data);
    }

    const handleJsChange = (data) => {
        setJsScript(data);
    }

    const handleCssChange = (data) => {
        setCssStyle(data);
    }

    const saveChanges = () => {
        let token = localStorage.getItem('token') || null;
        axios.post(`${BASE_URL}/template`, {
            "html_body": htmlBody,
            "css_body": cssStyle,
            "js_body": jsScript
        }, {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then((resp) => {
            console.log(resp.data);
            // window.location.reload();
            setTemplateUrl("");
            setTimeout(() => {
                setTemplateUrl(`https://cdn.learnwithguruji.com/apps/${resp.data.result.name}/template/index.html?time=${new Date().getTime()}`);
            }, 3000);
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <Fragment>
            <div style={{ width: '100%', margin: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <button className="btn btn-primary" onClick={() => saveChanges()}>Save &amp; Preview</button>
            </div>
            <Tabs defaultActiveKey="html" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="html" title="HTML">
                    <CodeEditor
                        mode="html"
                        theme="twilight"
                        name="html_body"
                        editorValue={htmlBody}
                        style={{ flex: 1 }}
                        onChange={handleHtmlChange} />
                </Tab>
                <Tab eventKey="css" title="CSS">

                    <CodeEditor
                        mode="css"
                        theme="twilight"
                        name="html_body"
                        editorValue={cssStyle}
                        style={{ flex: 1 }}
                        onChange={handleCssChange} />
                </Tab>
                <Tab eventKey="javascript" title="JavaScript">
                    <CodeEditor
                        mode="javascript"
                        theme="twilight"
                        name="html_body"
                        editorValue={jsScript}
                        style={{ flex: 1 }}
                        onChange={handleJsChange} />
                </Tab>
            </Tabs>
            <iframe title='Website-editor-preview' id="template-preview" style={{ flex: 1, height: '500px', border: 'solid 1px #e6e6e6', borderRadius: '5px' }} src={templateUrl} />
        </Fragment>
    );
}

export default WebsiteEditor;