import React from 'react';

const CetrificateGenerator = (props) => {
  return (
    <div>Certificate</div>
  )
}


export default CetrificateGenerator
