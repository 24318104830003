import React from 'react'

import { Route, Redirect } from 'react-router-dom';


export const ProtectedRoute = ({component: Component, ...rest}) => {
  let isAuthenticated = localStorage.getItem('auth');
  return (
    <Route
        {...rest}
        render={ props => {
          if (isAuthenticated){
            return <Component {...props} />
          }else{
            return <Redirect to="/login" />
          }
        }

        }
     />
  )
}
