import React, { useEffect, useState } from 'react';
import './style.scss';
import Navbar from '../../components/navbar';
import history from '../../utils/history';
import { useSelector, useDispatch } from 'react-redux';
import DefaultCardImage from './../../assets/default-course-card-img.png';
import {  Alert, Dropdown } from 'react-bootstrap';
import CreateCourseModel from '../../components/models/create-course';
import CreateQuizModel from '../../components/models/create-quiz';
import AddQuizQuestionModel from '../../components/models/add-quiz-question';
import DeleteQuizModel from '../../components/models/delete-quiz';
import QuizCard from '../../components/quiz-card';
import DashboardSideBar from './side-bar';
import EnableTutorAccountAccess from './become-tutor';
import { DashboardGlobalUploader } from './uploader';
import {
  getAllCourses
} from '../../actions/course';
import { useTranslation } from 'react-i18next';

import {
  getAllQuizzes
} from '../../actions/quiz';


import {
  fetchUserWithToken
} from '../../actions/user';

import MyCourses from './my-courses';
import ManageApplicationConfig from './application-config';
import { Fragment } from 'react';


import WebsiteEditor from '../../components/website-editor';

import Analytics from './analytics';
import { DefaultRootState } from '../../types/state';


function DashboardPage() {
  const { t } = useTranslation();

  const dispatch = useDispatch();


  
  const [current_quiz_question_id, set_current_quiz_question_id] = useState(null);

  const [show, setShow] = useState(false);
  const [showDeleteQuizModel, setShowDeleteQuizModel] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [showQuizQuestionModel, setShowQuizQuestionModel] = useState(false);
  const [quiz_id_model, set_quiz_id_model] = useState(undefined);

  const handleClose = () => setShow(false);
  const handleCloseDeleteQuizModel = () => setShowDeleteQuizModel(false);
  const handleCloseQuiz = () => setShowQuiz(false);
  const handleCloseQuizQuestionModel = () => setShowQuizQuestionModel(false);


  const handleShow = () => setShow(true);
  const handleShowDeleteQuizModel = () => setShowDeleteQuizModel(true);
  const handleShowQuiz = () => setShowQuiz(true);

  function handleShowDeleteQuizModelFunc(quiz_id) {
    setShowDeleteQuizModel(true);
    set_quiz_id_model(quiz_id);
  }

  const handleShowQuizQuestionModel = (quiz_id) => {
    set_current_quiz_question_id(quiz_id);
    setShowQuizQuestionModel(true);
  }

  

  



  

  

  const token = useSelector((state: DefaultRootState) => {
    return state.auth.token
  })

  const courses = useSelector((state: DefaultRootState) => state.course.courses.courses)

  const user = useSelector((state: DefaultRootState) => state.user.user)
  const isTutor = useSelector((state: DefaultRootState) => state.user.user.tutor)

  const courses_applied = useSelector((state: DefaultRootState) => state.course.courses.applied)

  const my_quizzes = useSelector((state: DefaultRootState) => state.quiz.quizzes)
  const defaultView = 'analytics';
  const [activeView, setActiveView] = useState(defaultView);

  useEffect(() => {
    dispatch(fetchUserWithToken(token))
    dispatch(getAllCourses())
    dispatch(getAllQuizzes())
    const redirect_url = localStorage.getItem('redirect');
    if (redirect_url) {
      history.push(new URL(redirect_url).pathname)
      localStorage.removeItem('redirect');
      // window.location.reload();
      return;
    }
  },[dispatch, token])

  return (
    <>
      <Navbar activePage="dashboard" />
      <DeleteQuizModel quiz_id={quiz_id_model} show={showDeleteQuizModel} handleShow={handleShowDeleteQuizModel} handleClose={handleCloseDeleteQuizModel} />
      <CreateCourseModel show={show} handleShow={handleShow} handleClose={handleClose} />
      <AddQuizQuestionModel show={showQuizQuestionModel} quiz_id={current_quiz_question_id} handleShow={handleShowQuizQuestionModel} handleClose={handleCloseQuizQuestionModel} />
      <CreateQuizModel show={showQuiz} handleShow={handleShowQuiz} handleClose={handleCloseQuiz} />
      <div className="col-md-12 dashboard-page">
        <DashboardSideBar isTutor={isTutor} setActiveView={setActiveView} />
        <div className="content" style={{ width: isTutor ? '80%' : '100%' }}>
          <div className="container-fluid">
            <div className='row'>
              {activeView === 'my-courses' && isTutor && <div className="col-md-12 action-bar-dashboard">
                {user.kyc_status && <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-create">
                    {t('dashboardCreateButton')}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {user && user.kyc_status && <Dropdown.Item href="#" onClick={handleShow}>{t('dashboardCreateButtonCreateCourse')}</Dropdown.Item>}
                    {/* {user && user.kyc_status && <Dropdown.Item href="#" onClick={handleShowQuiz}>Create Quiz</Dropdown.Item>} */}
                  </Dropdown.Menu>
                </Dropdown>}
              </div>}
              {activeView === 'my-courses' && isTutor && <div className="col-md-12 course-section">
                <MyCourses courses={courses} />
              </div>}
              {activeView === 'manage-website' && isTutor && <div className="col-md-12 course-section">
                <WebsiteEditor />
              </div>}
              {activeView === 'courses-applied' && courses_applied && <div className='col-md-12'>
                {courses_applied.length === 0 && <Fragment>
                  <p>{t('dashboardNoCourseFoundText')}</p>
                  <hr />
                  {!isTutor && <EnableTutorAccountAccess />}
                </Fragment>}
              </div>}
              {activeView === 'uploader' && <div className="col-md-12 course-section">
                <DashboardGlobalUploader />
              </div>}
              {activeView === 'courses-applied' && courses_applied && <div className="col-md-12 course-section">
                {courses_applied.map((course, i) => <div key={"c_" + course.key} className="course">
                  <img src={course.card_image || DefaultCardImage} alt="Card-img"/>
                  <h1>{course.title}</h1>
                  <span className="cgoal">{course.goal}</span>
                  <a className="btn btn-success" href={"/course/view/" + course.key}>{course.admin ? "Edit" : "View"}</a>
                </div>
                )}
              </div>}
              {activeView === 'my-quizzes' && isTutor && <div className="col-md-12 quiz-section">
                {my_quizzes && my_quizzes.my_quizzes && <div className="row quiz-tab-container">
                  {my_quizzes.my_quizzes
                    .sort((a, b) => a.id > b.id ? 1 : -1)
                    .map((q, i) => <QuizCard quiz={q} handleShowDeleteQuizModel={() => handleShowDeleteQuizModelFunc(q.id)} handleShowQuizQuestionModel={handleShowQuizQuestionModel} />)}
                </div>}
              </div>}
              {activeView === 'manage-application' && isTutor && <ManageApplicationConfig />}
              {activeView === 'analytics' && isTutor && <Analytics />}
            </div>



            {isTutor ? (
              <div className="row">
                <div className="col-md-12">
                  {user && !user.kyc_status && <Alert variant="warning">
                    <h1>For us to work with you</h1>
                    <p>Submit Your KYC, this will help us understand you better and to continue with our services.</p>
                    <p>Why we need this from you?</p>
                    <ul>
                      <li>To verify Individual/Company.</li>
                      <li>To verify your bank account details for processing payments.</li>
                      <li>To resolve issue with your account.</li>
                      <li>To help you publish content.</li>
                    </ul>
                    <p>If you have any query with KYC process, please reach us at <a href="mailto:support@learnwithguruji.com">support@learnwithguruji.com</a>.</p>
                    <a href="/submit-kyc" className="btn btn-success">Submit KYC</a>
                  </Alert>}
                </div>

              </div>
            ) : (<></>)}
          </div>
        </div>
      </div>
    </>
  );
}



export default DashboardPage;
