import React, {useState, useEffect} from 'react';
import {  useSelector, useDispatch } from 'react-redux';

import {Button, Form, Alert} from 'react-bootstrap';

import './styles.scss';


import {BASE_URL} from './../../config';
import uploadService from './../../utils/uploader';

import {
  fetchUserWithToken
} from './../../actions/user';

const email_regex = /^[-!#$%&'*+0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

function SubmitKYCPage() {

  const dispatch = useDispatch();



  const [errors, set_errors] = useState({});
  const [company, set_company] = useState("");
  const [description, set_description] = useState("");
  const [email, set_email] = useState("");
  const [website, set_website] = useState("");
  const [contact, set_contact] = useState("");
  const [gst, set_gst] = useState("");
  const [pan, set_pan] = useState("");
  const [address, set_address] = useState("");
  const [primaryBusiness, set_primaryBusiness] = useState("");
  const [bankName, set_bank_name] = useState("");
  const [bankIFSC, set_bankIFSC] = useState("");
  const [bankAccountNumber, set_bank_account_number] = useState("");
  const [bank_account_holder_name, set_bank_account_holder_name] = useState("");
  const [bank_cancle_Cheque, set_bank_cancle_Cheque] = useState("");
  const [pan_card, set_pan_card] = useState("");
  const [iagree, set_iagree] = useState(false);
  const [iagreeCreatorAgreenment, set_iagree_creator_agreenment] = useState(false);

  const kyc_status = useSelector(state => {
    return state.user.user.kyc_status
  })

  const token = useSelector(state => {
    return state.auth.token
  })

  useEffect(()=>{
    dispatch(fetchUserWithToken(token))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function submitKYC(){
    if (company === ''){
      set_errors({
        company:'Company/Service Name Required!'
      })
      return;
    }

    if (company.length < 2){
      set_errors({
        company:'Company/Service name must contain minium of two or more characters.'
      })
      return;
    }

    if (description === ''){
      set_errors({
        description: 'Your company/Service description required!'
      })
      return;
    }
    //
    // if (description.length < 50) {
    //   set_errors({
    //     description: 'Your Company/Service Description must be atleast 50 or more characters.'
    //   })
    //   return;
    // }

    if (email === '') {
      set_errors({
        email: 'Email Required!'
      })
      return;
    }

    if (!email_regex.test(email)){
      set_errors({
        email: 'Please enter valid email'
      })
      return;
    }

    if (website!==''){
    
      try {
       
        set_errors({
          website: ''
        })
      } catch (e) {
        set_errors({
          website: 'Please enter a valid website URL'
        })
        return;
      }
    }

    if (contact === ''){
      set_errors({
        contact: 'Contact Required!'
      })
      return;
    }

    if (gst === ''){
      set_errors({
        gst: 'GST Number Required!'
      })
      return;
    }

    if (pan === ''){
      set_errors({
        pan: 'PAN Number Required!'
      })
      return;
    }

    if (primaryBusiness === ''){
      set_errors({
        primaryBusiness: 'Primary Business Goal Required!'
      })
      return;
    }

    if (bankName === ''){
      set_errors({
        bankName: 'Bank Name Required!'
      })
      return;
    }

    if (bankIFSC === ''){
      set_errors({
        bankIFSC: 'Bank IFSC Code Required!'
      })
      return;
    }

    if (bankAccountNumber === ''){
      set_errors({
        bankAccountNumber: 'Bank Account Number Required!'
      })
      return;
    }


    if (bank_account_holder_name === ''){
      set_errors({
        bank_account_holder_name: 'Bank Account Holder Name Required!'
      })
      return;
    }

    if (bank_cancle_Cheque === ''){
      set_errors({
        bank_cancle_Cheque: 'Bank Cancle Cheque Scanned Copy Required!'
      })
      return;
    }

    if (pan_card === ''){
      set_errors({
        pan_card: 'PAN Card Scanned Copy Required!'
      })
      return;
    }

    let token = localStorage.getItem('token') || null;

    fetch(`${BASE_URL}/user/submitkyc`, {
      method: "POST",
      body: JSON.stringify({
        company: company,
        description: description,
        email: email,
        website: website,
        contact: contact,
        gst: gst,
        pan: pan,
        address: address,
        primaryBusiness: primaryBusiness,
        bankName: bankName,
        bankIFSC: bankIFSC,
        bankAccountNumber: bankAccountNumber,
        bank_account_holder_name: bank_account_holder_name,
        bank_cancle_Cheque: bank_cancle_Cheque,
        pan_card: pan_card
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer "+token,
      }
    }).then((response)=>response.json())
    .then((data)=>{
      console.log('kyc submitted');
      window.location = "/dashboard";
    })




  }
  return (
    <>
    {kyc_status && <div className="container-fluid kyc-container">
      <p>Your KYC is already submitted, if you are facing any issue accessing our services contact us at <a href="mailto:support@learnwithguruji.com">support@learnwithguruji.com</a></p>
      <a className="btn btn-primary" href="/dashboard">Go To Dashboard</a>
      </div>}
    {!kyc_status && <div className="container-fluid kyc-container">
    <div className="row">
      <div className="col-md-12">
        <h1>Submit Your KYC Details</h1>
        <p>Please complete your KYC details and click on the submit button to continue.</p>
      </div>
      <div className="col-md-12">
        <Form.Group controlId="companyName">
          <Form.Label>Name</Form.Label>
          <Form.Control value={company} onChange={(e)=>set_company(e.target.value)} type="text" placeholder="Company/Service" />
          {errors.company && <Alert key="username" style={{marginTop: '5px'}} variant="danger">
            {errors.company}
          </Alert>}
          <Form.Text className="text-muted">
            Enter your company name.
          </Form.Text>
        </Form.Group>
      </div>
      <div className="col-md-12">
        <Form.Group controlId="companyDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control value={description} onChange={(e)=>set_description(e.target.value)} type="text" placeholder="Comapny/Service Description" />
          {errors.description && <Alert key="username" style={{marginTop: '5px'}} variant="danger">
            {errors.description}
          </Alert>}
          <Form.Text className="text-muted">
            Enter your company/Service description minium 50 characters.
          </Form.Text>
        </Form.Group>
      </div>
      <div className="col-md-12">
      <Form.Group controlId="companyEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control value={email} onChange={(e)=>set_email(e.target.value)} type="text" placeholder="Primary Contact Email" />
        {errors.email && <Alert key="username" style={{marginTop: '5px'}} variant="danger">
          {errors.email}
        </Alert>}
        <Form.Text className="text-muted">
          Enter your company email address.
        </Form.Text>
      </Form.Group>
      </div>
      <div className="col-md-12">
      <Form.Group controlId="companyWebsite">
        <Form.Label>Website (Optional)</Form.Label>
        <Form.Control value={website} onChange={(e)=>set_website(e.target.value)} type="url" placeholder="Website URL (Optional)" />
        {errors.website && <Alert key="username" style={{marginTop: '5px'}} variant="danger">
          {errors.website}
        </Alert>}
        <Form.Text className="text-muted">
          Enter your company website address.
        </Form.Text>
      </Form.Group>
      </div>
      <div className="col-md-12">
      <Form.Group controlId="companyContact">
        <Form.Label>Contact</Form.Label>
        <Form.Control value={contact} onChange={(e)=>set_contact(e.target.value)} type="text" placeholder="Primary Contact/Mobile Number" />
        {errors.contact && <Alert key="username" style={{marginTop: '5px'}} variant="danger">
          {errors.contact}
        </Alert>}
        <Form.Text className="text-muted">
          Enter your company contact infromation.
        </Form.Text>
      </Form.Group>
      </div>
      <div className="col-md-12">
      <Form.Group controlId="companyGST">
        <Form.Label>GST NO (Optional)</Form.Label>
        <Form.Control value={gst} onChange={(e)=>set_gst(e.target.value)} type="text" placeholder="GST No (Optional)" />
        {errors.gst && <Alert key="username" style={{marginTop: '5px'}} variant="danger">
          {errors.gst}
        </Alert>}
        <Form.Text className="text-muted">
          Enter your company GST number.
        </Form.Text>
      </Form.Group>
      </div>
      <div className="col-md-12">
      <Form.Group controlId="companyPAN">
        <Form.Label>PAN No.</Form.Label>
        <Form.Control value={pan} onChange={(e)=>set_pan(e.target.value)} type="text" placeholder="Your PAN No." />
        {errors.pan && <Alert key="username" style={{marginTop: '5px'}} variant="danger">
          {errors.pan}
        </Alert>}
        <Form.Text className="text-muted">
          Enter your PAN number.
        </Form.Text>
      </Form.Group>
      </div>
      <div className="col-md-12">
      <Form.Group controlId="companyAddress">
        <Form.Label>Company Office Address (Optional)</Form.Label>
        <Form.Control value={address} onChange={(e)=>set_address(e.target.value)} type="text" placeholder="Office Address (Optional)" />
        {errors.address && <Alert key="username" style={{marginTop: '5px'}} variant="danger">
          {errors.address}
        </Alert>}
        <Form.Text className="text-muted">
          Enter your Company Address.
        </Form.Text>
      </Form.Group>
      </div>
      <div className="col-md-12">
      <Form.Group controlId="companyType">
        <Form.Label>Primary Business Goal</Form.Label>
        <Form.Control value={primaryBusiness} onChange={(e)=>set_primaryBusiness(e.target.value)} type="text" placeholder="Education Services, Online Tutuor Service" />
        {errors.primaryBusiness && <Alert style={{marginTop: '5px'}} variant="danger">
          {errors.primaryBusiness}
        </Alert>}
        <Form.Text className="text-muted">
          Enter your primary business goal for example Education Services, Online Tutuor Service.
        </Form.Text>
      </Form.Group>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <h1>Bank Details</h1>
        <p>We collect your bank details to process payments to your bank account. Read more in our <a href="/terms">Terms &amp; Condition</a></p>
        <p>Please make sure and double check your bank details before submitting, You are only allowed to submit it once.</p>
      </div>
      <div className="col-md-12">
      <Form.Group controlId="bankName">
        <Form.Label>Bank Name</Form.Label>
        <Form.Control value={bankName} onChange={(e)=>set_bank_name(e.target.value)} type="text" placeholder="Your Bank Name" />
        {errors.bankName && <Alert style={{marginTop: '5px'}} variant="danger">
          {errors.bankName}
        </Alert>}
        <Form.Text className="text-muted">
          Enter your bank name.
        </Form.Text>
      </Form.Group>
      </div>
      <div className="col-md-12">
      <Form.Group controlId="bankIFSCCode">
        <Form.Label>Bank IFSC Code</Form.Label>
        <Form.Control value={bankIFSC} onChange={(e)=>set_bankIFSC(e.target.value)} type="text" placeholder="Your Bank IFSC Code" />
        {errors.bankIFSC && <Alert style={{marginTop: '5px'}} variant="danger">
          {errors.bankIFSC}
        </Alert>}
        <Form.Text className="text-muted">
          Enter your bank IFSC Code here.
        </Form.Text>
      </Form.Group>
      </div>
      <div className="col-md-12">
      <Form.Group controlId="BankAccountNumber">
        <Form.Label>Bank Account Number</Form.Label>
        <Form.Control value={bankAccountNumber} onChange={(e)=>set_bank_account_number(e.target.value)} type="text" placeholder="Your Bank Account Number" />
        {errors.bankAccountNumber && <Alert style={{marginTop: '5px'}} variant="danger">
          {errors.bankAccountNumber}
        </Alert>}
        <Form.Text className="text-muted">
          Enter your bank Account Number.
        </Form.Text>
      </Form.Group>
      </div>
      <div className="col-md-12">
      <Form.Group controlId="BankAccountNumber">
        <Form.Label>Bank Account Holder Name</Form.Label>
        <Form.Control value={bank_account_holder_name} onChange={(e)=>set_bank_account_holder_name(e.target.value)} type="text" placeholder="Your Bank Account Holder Name" />
        {errors.bank_account_holder_name && <Alert style={{marginTop: '5px'}} variant="danger">
          {errors.bank_account_holder_name}
        </Alert>}
        <Form.Text className="text-muted">
          Enter your bank account holder name.
        </Form.Text>
      </Form.Group>
      </div>
      <div className="col-md-12">
        <h1>Upload Supporting Documents Proofs</h1>
      </div>
      <div className="col-md-12">
        <Form.Group>
          <div>
            <Button variant="link" href={bank_cancle_Cheque}>
              <img alt='ada' src={bank_cancle_Cheque} className="kyc-image-preview" />
            </Button>
          </div>
          <Form.File onChange={
            (file)=>{
              uploadService(file, (p)=>{
                console.log(p);
              }, (resp)=>{
                console.log(resp);
                set_bank_cancle_Cheque(resp.url)
              });
            }
          } label="Upload Bank Cancle Cheque" />
          {errors.bank_cancle_Cheque && <Alert style={{marginTop: '5px'}} variant="danger">
            {errors.bank_cancle_Cheque}
          </Alert>}
        </Form.Group>
      </div>
      <div className="col-md-12">
        <Form.Group>
          <div>
            <Button variant="link" href={pan_card}>
              <img alt='kyc-img' src={pan_card} className="kyc-image-preview" />
            </Button>
          </div>
          <Form.File onChange={
            (file)=>{
              uploadService(file, (p)=>{
                console.log(p);
              }, (resp)=>{
                console.log(resp);
                set_pan_card(resp.url)
              });
            }
          } label="Upload Your PAN Scanned Photo" />
          {errors.pan_card && <Alert style={{marginTop: '5px'}} variant="danger">
            {errors.pan_card}
          </Alert>}
        </Form.Group>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <Form.Group controlId="iagree">
          <Form.Check onChange={(e)=>set_iagree(!iagree)} type="checkbox" label="All information are correct to best of my knowledge, I agree to all Terms & Condition and Privacy Policy of website." />
        </Form.Group>
        <Form.Group controlId="iagreeCreatorAgreenment">
          <Form.Check  onChange={(e)=>set_iagree_creator_agreenment(!iagreeCreatorAgreenment)} type="checkbox" label="I have carefully read and accepted Creator Agreement of website." />
        </Form.Group>
        <p>Click here to view <a href="/terms">Terms &amp; Condition</a>, <a href="/agreement-creator">Creator Agreement</a> and <a href="/privacy">Privacy Policy</a>.</p>
      </div>
      <div className="col-md-12">
        <Button disabled={iagree === true && iagreeCreatorAgreenment === true? false : true} onClick={()=>submitKYC()}>Submit KYC</Button>
      </div>
    </div>

    </div>}
    </>
  );
}

export default SubmitKYCPage;
