import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import MetaTags from 'react-meta-tags';
import 'github-markdown-css';
import ReactMarkdown from 'react-markdown';
import 'react-markdown-editor-lite/lib/index.css';
import axios from 'axios';
import { BASE_URL } from '../../config';
import { Spinner } from 'react-bootstrap';
import './style.scss';
import CustomVideoPlayer from '../../components/video-player';
import DefaultCardImage from './../../assets/default-course-card-img.png';
import Footer from '../../components/footer';
import FullPageLoading from '../../components/full-page-loading';

const queryClient = new QueryClient()

function CTAButton({ course_id, data }) {
  const offer = parseInt(data.fee) - parseInt(`${data.fee * (data.discount / 100)}`)
  const enrollNow = () => {
    let token = localStorage.getItem('token') || null;
    axios.post(`${BASE_URL}/enroll/course/${course_id}`, {}, {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token,
      }
    }).then((response) => {
      console.log(response);
      if (response.data.message === "COURSE_ENROLLED_ALREADY_SUCCESS") {
        window.location = response.data.result.redirect_url
      }

      if (response.data.message === "COURSE_PAYMENT_REQUIRED_SUCCESS") {
        window.location = response.data.result.payment_url
      }

    })
      .catch((error) => {
        console.log(error);
      })
  }
  return (
    <div className="col-md-12 buy-now-section">
      {data.discount > 0 && <a href='#0' onClick={() => enrollNow()} style={{ margin: '25px 0px' }} className="btn btn-primary payment-url-btn">Enroll Now @ ₹ {offer} <span className="strike">₹ {data.fee}</span> <span className="discount">({data.discount} % OFF)</span></a>}
      {data.discount === 0 && <a href='#0' onClick={() => enrollNow()} style={{ margin: '25px 0px' }} className="btn btn-primary payment-url-btn">Enroll Now @ ₹ {data.fee}</a>}
    </div>
  )
}

const CoursePublicPage = ({ match }) => {
  const course_id = match.params.slug;
  const { isLoading, data } = useQuery('repoData', () =>
    fetch(`https://cdn.learnwithguruji.com/courses/${course_id}/${course_id}.json`).then(res =>
      res.json()
    )
  )

  if (isLoading) {
    return (
      <FullPageLoading />
    )
  }

  return (

    <div>
      {<MetaTags>
        <title>{`${data.title || 'loading...'} Course`}</title>
        <meta name="description" content={data.description} />
        <meta property="og:title" content={data.title} />
        <meta property="og:image" content={data.social_image} />
      </MetaTags>}
      <div className="container-fluid" id="course-section">
        <div className="row top-strip">
          <div className="col-md-12 page-heading">
            {data ? (
              <>
                <div>
                  <a href="/">
                    <div className="title-description-section">
                      <div className="course-card">
                        <img alt='Card-img' src={data.card_image || DefaultCardImage} />
                      </div>
                      <div className="course-info">
                        <h1>{data.title}</h1>
                        <p>{data.goal}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </>
            ) : (
              <div>
                <div className="loading-img">
                  <Spinner animation="border" variant="light" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row chapters-section-container banner-top-padding">

          <>
            {data ? (
              <div className="buy-alert-section container-fluid">
                <div className="row">
                  <img className="course-banner-image" alt="Banner-pic" src={data.banner_image} />
                  <div className="course-description">
                    <div className="course-description-content markdown-body">
                      {data.intro_video && <div className="intro-video-public">
                        <CustomVideoPlayer src={data.intro_video} />
                      </div>}
                      <div className="action-cta-button-bottom">
                        <CTAButton course_id={match.params.slug} data={data} />
                      </div>
                      <ReactMarkdown source={data.description} />
                    </div>
                    <div className="quick-buy-cta">
                      <CTAButton course_id={match.params.slug} data={data} />
                    </div>
                  </div>
                </div>

                <div className="row action-cta-button-bottom">
                  <CTAButton course_id={match.params.slug} data={data} />
                </div>
                <Footer />
              </div>

            ) : (<></>)}
          </>

        </div>


      </div>
    </div>

  )
}


function CourseView({ match }) {
  const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
  }
  const getBrowser = () => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }
    return browserName;
  }

  const mobileAndTabletCheck = ():boolean => {
    let check = false;
    (function (a) { if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(a.substr(0, 4))) check = true; })(navigator.userAgent || navigator.vendor);
    return check;
  };

  const getDeviceType = () => {
    const check = mobileAndTabletCheck();
    return check ? `Mobile-Browser` : `Website`;
  }

  const sendAnalyticsEvent = async (courseId: string) => {
    const token = localStorage.getItem('token') || null;
    const header = { 'headers': { 'Authorization': "Bearer " + token } };
    const analyticsEventCourseViewDate = localStorage.getItem('analytics-event-course-view');
    if (analyticsEventCourseViewDate) {
      if (isToday(new Date(analyticsEventCourseViewDate))) {
        console.log('Analytics Event : already sent for today!');
        return;
      }
    }
    localStorage.setItem(`analytics-event-course-view`, new Date().toISOString());
    await axios.post(`${BASE_URL}/analytics`, {
      "meta": {
        "browser": getBrowser(),
        "device": getDeviceType()
      },
      "course_id": courseId,
      "type": "course"
    }, header);
    console.log(`Analytics Event : sent!`);

  }
  useEffect(() => {
    sendAnalyticsEvent(match.params.slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[] );
  return (
    <QueryClientProvider client={queryClient}>
      <CoursePublicPage match={match} />
    </QueryClientProvider>
  );
}

export default CourseView;
