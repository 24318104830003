import React from 'react';
import { Route, Switch } from 'react-router'
import HomePage from '../pages/home';
import CoursePage from '../pages/course';
import CoursePublicPage from '../pages/course-public';
import LoginPage from '../pages/login';
import LogoutPage from '../pages/logout';
import DashboardPage from '../pages/dashboard';
import SignupPage from '../pages/signup';
import CetrificateGenerator from '../pages/certificate';
import {ProtectedRoute} from '../components/protectedroute';
import ConfirmPayment from '../pages/confirmpayment';
import GlobalTermsAndConditions from '../pages/terms';
import GlobalPrivacy from '../pages/privacy';
import GlobalRefundPolicy from '../pages/refund';
import GlobalAgreement from '../pages/agreement';
import GlobalAgreementCompany from '../pages/agreement-company';
import Playground from '../pages/playground';
import UpdatePasswordPage from '../pages/updatepassword';
import ForgotPasswordPage from '../pages/resetpassword';
import InstructorProfile from '../pages/instructor';
import QuizPage from '../pages/quiz';
import SubmitKYCPage from '../pages/kyc';
import PlaygroundGameLearnABCD from '../games/learn-abcd';
import ContactPage from '../pages/contact';
import { BuilderMain } from '../components/builder';
import Onboarding from '../pages/oboarding';
import MeetingPageComponent from '../pages/meeting';
const routes = (
  <div>
    <Switch>
      <Route path="/course/view/:slug" render={props => <CoursePublicPage {...props} />} />
      <Route path="/course/:slug" render={props => <CoursePage {...props} />} />
      <Route path="/confirmpayment" render={props => <ConfirmPayment {...props} />} />
      <Route path="/login" render={props=><LoginPage {...props}/>} />
      <Route path="/logout" render={props=><LogoutPage {...props}/>} />
      <Route path="/submit-kyc" render={props=><SubmitKYCPage {...props}/>} />
      <Route path="/forgotpassword" render={props=><ForgotPasswordPage {...props}/>} />
      <Route path="/updatepassword" render={props=><UpdatePasswordPage {...props}/>} />
      <Route path="/terms" render={props=><GlobalTermsAndConditions {...props}/>} />
      <Route path="/privacy" render={props=><GlobalPrivacy {...props}/>} />
      <Route path="/agreement-creator" render={props=><GlobalAgreement {...props}/>} />
      <Route path="/agreement-company" render={props=><GlobalAgreementCompany {...props}/>} />
      <Route path="/refund" render={props=><GlobalRefundPolicy {...props}/>} />
      <Route path="/meeting/:room_id" component={MeetingPageComponent} />
      <Route path="/signup" component={SignupPage} />
      <Route path="/getcertificate" component={CetrificateGenerator} />
      <Route path="/quiz/:quiz_id" render={props=><QuizPage {...props}/>} />
      <Route path="/playground/learn-abcd" render={props=><PlaygroundGameLearnABCD {...props}/>} />
      <Route path="/playground" render={props=><Playground {...props}/>} />
      <Route path="/instructor/:slug" render={props=><InstructorProfile {...props}/>} />
      <ProtectedRoute path="/dashboard" component={DashboardPage} />
      <Route path="/contact" render={props => <ContactPage {...props} />} />
      <Route path="/builder" render={props => <BuilderMain {...props} />} />
      <Route path="/onboarding" render={props => <Onboarding {...props} />} />
      <Route path="/" >
        <HomePage />
      </Route>
    </Switch>
  </div>
)

export default routes
