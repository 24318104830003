import React, { useState } from 'react';
import {  useDispatch } from "react-redux";
import {Button, Form} from 'react-bootstrap';

import './style.scss';



import parseParams from './../../utils/parse-query-params';

import {updatePassword} from '../../actions/auth';


function UpdatePasswordPage({match, location}){


  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);

  function resetPassword (){
    const params_data = parseParams(location.search);
    dispatch(updatePassword(params_data.email, newPassword, params_data.token))
  }

  return (
    <div className="container password-section">
      <div className="row">
        <div className="col-md-12">
          <h1>Reset Your Password</h1>
        </div>
        <div className="col-md-12">
          <Form.Group controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control onChange={(e)=>{
              setNewPassword(e.target.value)
            }} type="password" placeholder="Your Password" />
            <Form.Text className="text-muted">
              Enter Your New Password
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control onChange={(e)=>{
              if (e.target.value === newPassword){
                setPasswordValid(true)
              }else{
                setPasswordValid(false)
              }
            }} type="password" placeholder="Confirm Password" />
            <Form.Text className="text-muted">
              Repeat the Password once again
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="resetPasswordButton">
          <Button disabled={!passwordValid} variant="primary" onClick={resetPassword}>
            Update Password
          </Button>
          </Form.Group>
        </div>
      </div>
    </div>
  );

}


export default UpdatePasswordPage;
