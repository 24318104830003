import axios from 'axios';
import history from './../utils/history';

import {BASE_URL} from './../config';

import {logoutUser} from './auth';


export const GET_ALL_COURSE_REQUEST = 'GET_ALL_COURSE_REQUEST';
export const GET_ALL_COURSE_SUCCESS = 'GET_ALL_COURSE_SUCCESS';
export const GET_ALL_COURSE_ERROR = 'GET_ALL_COURSE_ERROR';

export const COURSE_PREVIEW_REQUEST = 'COURSE_PREVIEW_REQUEST';
export const COURSE_PREVIEW_SUCCESS = 'COURSE_PREVIEW_SUCCESS';
export const COURSE_PREVIEW_ERROR = 'COURSE_PREVIEW_ERROR';

export const COURSE_PREVIEW_PAGE_REQUEST = 'COURSE_PREVIEW_PAGE_REQUEST';
export const COURSE_PREVIEW_PAGE_SUCCESS = 'COURSE_PREVIEW_PAGE_SUCCESS';
export const COURSE_PREVIEW_PAGE_ERROR = 'COURSE_PREVIEW_PAGE_ERROR';

export const CREATE_COURSE_PAYMENT_REQUEST = 'CREATE_COURSE_PAYMENT_REQUEST';
export const CREATE_COURSE_PAYMENT_SUCCESS = 'CREATE_COURSE_PAYMENT_SUCCESS';
export const CREATE_COURSE_PAYMENT_ERROR = 'CREATE_COURSE_PAYMENT_ERROR';

export const GET_ALL_FEATURED_COURSE_REQUEST = 'GET_ALL_FEATURED_COURSE_REQUEST';
export const GET_ALL_FEATURED_COURSE_SUCCESS = 'GET_ALL_FEATURED_COURSE_SUCCESS';
export const GET_ALL_FEATURED_COURSE_ERROR = 'GET_ALL_FEATURED_COURSE_ERROR';

export const CREATE_COURSE_REQUEST = 'CREATE_COURSE_REQUEST';
export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export const CREATE_COURSE_ERROR = 'CREATE_COURSE_ERROR';

export const CREATE_COURSE_APPLY_REQUEST = 'CREATE_COURSE_APPLY_REQUEST';
export const CREATE_COURSE_APPLY_SUCCESS = 'CREATE_COURSE_APPLY_SUCCESS';
export const CREATE_COURSE_APPLY_ERROR = 'CREATE_COURSE_APPLY_ERROR';

export const UPDATE_COURSE_REQUEST = 'UPDATE_COURSE_REQUEST';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const UPDATE_COURSE_ERROR = 'UPDATE_COURSE_ERROR';

export const REQUEST_COURSE = 'REQUEST_COURSE';
export const COURSE_FAILED = 'COURSE_FAILED';
export const RECEIVE_COURSE = 'RECEIVE_COURSE';

export const CHAPTER_GET_ALL = 'CHAPTER_GET_ALL';

export const CHAPTER_CREATED_FAILED = 'CHAPTER_CREATED_FAILED';
export const CHAPTER_CREATED_SUCCESS = 'CHAPTER_CREATED_SUCCESS';

export const CHAPTER_LOAD_CONTENT = 'CHAPTER_LOAD_CONTENT';

export const CHAPTER_REMOVED_SUCCESS = 'CHAPTER_REMOVED_SUCCESS';
export const CHAPTER_REMOVED_ERROR = 'CHAPTER_REMOVED_ERROR';

export const CHAPTER_UPDATE_ERROR = 'CHAPTER_UPDATE_ERROR';
export const CHAPTER_UPDATE_SUCCESS = 'CHAPTER_UPDATE_SUCCESS';

export const CHAPTER_VIDEO_ADDED_ERROR = 'CHAPTER_VIDEO_ADDED_ERROR';
export const CHAPTER_VIDEO_ADDED_SUCCESS = 'CHAPTER_VIDEO_ADDED_SUCCESS';

export const CHAPTER_VIDEO_REMOVE_ERROR = 'CHAPTER_VIDEO_REMOVE_ERROR';
export const CHAPTER_VIDEO_REMOVE_SUCCESS = 'CHAPTER_VIDEO_REMOVE_SUCCESS';

export function previewCourse(){
  return dispatch => {
    dispatch({
      type: COURSE_PREVIEW_REQUEST
    })
    dispatch({
      type: COURSE_PREVIEW_SUCCESS
    })
  }
}

export function previewCoursePage(){
  return dispatch => {
    dispatch({
      type: COURSE_PREVIEW_PAGE_REQUEST
    })
    dispatch({
      type: COURSE_PREVIEW_PAGE_SUCCESS
    })
  }
}

export function coursePayment(payment){
  return dispatch => {
    let token = localStorage.getItem('token') || null;
    dispatch({
      type: CREATE_COURSE_PAYMENT_REQUEST
    })
    axios.post(`${BASE_URL}/course/payment`, payment, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: CREATE_COURSE_PAYMENT_SUCCESS,
         message: "Payment Done!"
       })
       history.push(response.data.redirect_url);
     })
     .catch((error)=>{
       dispatch({
         type: GET_ALL_FEATURED_COURSE_ERROR,
         message: "Payment Failed!"
       })
     })
  }
}

export function applyCourse(course){
  return dispatch => {
    let token = localStorage.getItem('token') || null;
    dispatch({
      type: CREATE_COURSE_APPLY_REQUEST
    })
    axios.post(`${BASE_URL}/course/apply`, course, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: CREATE_COURSE_APPLY_SUCCESS,
         message: "New Course Applied Successfully"
       })
       window.location.reload();
     })
     .catch((error)=>{
       dispatch({
         type: CREATE_COURSE_APPLY_ERROR,
         message: "New Course Apply failed!"
       })

       localStorage.setItem('redirect', window.location.href);

       history.push('/login')
     })
  }
}

export function updateCourse(course) {

  return dispatch => {
    let token = localStorage.getItem('token') || null;
    axios.put(`${BASE_URL}/course`, course, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: UPDATE_COURSE_SUCCESS,
         message: "Course Updated"
       })
       window.location.reload();
     })
     .catch((error)=>{
       dispatch({
         type: CREATE_COURSE_ERROR,
         message: "Course update failed!"
       })
     })
  }

}

export function getFeaturedCourses() {

  return dispatch => {

    dispatch({
      type: GET_ALL_FEATURED_COURSE_REQUEST,
      featured: []
    })

    let url = `${BASE_URL}/course/featured`;

    let token = localStorage.getItem('token') || null;

    axios.get(`${url}`, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: GET_ALL_FEATURED_COURSE_SUCCESS,
         featured: response.data
       })
     })
     .catch((error)=>{
       dispatch({
         type: GET_ALL_FEATURED_COURSE_ERROR,
         featured: [],
         message: "Unable to get featured courses!"
       })
     })

  }
}

export function getAllCourses(params) {

  return dispatch => {
    dispatch({
      type: GET_ALL_COURSE_REQUEST,
      courses: []
    })
    let url = `${BASE_URL}/course`;
    try {

      if (params.type === "public"){
        url = `${BASE_URL}/course?type=${params.type}`
      }
    } catch (e) {
      console.log(e);
    }

    let token = localStorage.getItem('token') || null;
    axios.get(`${url}`, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: GET_ALL_COURSE_SUCCESS,
         courses: response.data
       })
     })
     .catch((error)=>{
       dispatch({
         type: GET_ALL_COURSE_ERROR,
         message: "New Course creation failed!"
       })
     })

  }
}

export function createCourse(course){
  let token = localStorage.getItem('token') || null;
  return dispatch => {
    axios.post(`${BASE_URL}/course`, course, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: CREATE_COURSE_SUCCESS,
         message: "New Course created"
       })
       dispatch(getAllCourses())
     })
     .catch((error)=>{
       dispatch({
         type: CREATE_COURSE_ERROR,
         message: "New Course creation failed!"
       })
     })
  }
}




export function getCourse(slug) {
  let token = localStorage.getItem('token') || null;
  return (dispatch) => {
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
    };
    if (token === "null"){
      headers = {
        'Content-Type': 'application/json',
      }
    }
    axios.get(`${BASE_URL}/course/${slug}`, { 'headers': headers})
    .then((response)=>{
      const course = response.data;
      dispatch({
        type: RECEIVE_COURSE,
        course: course
      })
    })
    .catch((error)=>{
      try{
        if (error.response.data.error === "unauthorized"){
          let redirect_url = window.location.origin+`/course/${slug}`;
          localStorage.setItem('redirect', redirect_url);
          history.push('/login')
          window.location.reload();
          return;
        }
        if (error.response.data.error==="invalid token"){
          dispatch(logoutUser(error.response.data.error))
        }

        if (error.response.data.message==="Course is not verified!"){
          alert("Verify your course, contact support@learnwithguruji.com.")
        }
      }catch(e){
        console.log(e);
      }
    })
  }
}





function createChapterError(error){
  return dispatch => {
    dispatch({
      type: CHAPTER_CREATED_FAILED,
      error: 'unable to add new chapter',
    })
  }
}

export function getAllChapters(course){
  let token = localStorage.getItem('token') || null;
  return dispatch => {
    axios.get(`${BASE_URL}/course/${course.id}/chapter`, { 'headers': {'Authorization': "Bearer "+token }})
    .then((response)=>{
      dispatch({
        type: CHAPTER_GET_ALL,
        chapters: response.data
      })
    })
  }
}

export function createChapter(slug, chapter){

  let token = localStorage.getItem('token') || null;
  console.log("checkit", slug, chapter, token);
  return dispatch => {
    axios.post(`${BASE_URL}/course/chapter`, {
        course_id: chapter.course_id,
        title: chapter.title,
    }, { 'headers': { 'Authorization': "Bearer "+token } })
    .then((response)=>{
      dispatch({
        type: CHAPTER_CREATED_SUCCESS,
        chapter: response.data.chapter
      })
      window.location.reload();
    })
    .catch((error)=>{
      dispatch(createChapterError(error.response))
    })
  }
}

function addChapterVideoSuccess(chapter, newVideo){
  return dispatch => {
    dispatch({
      type: CHAPTER_VIDEO_ADDED_SUCCESS,
      video: newVideo,
      chapter: chapter
    })
  }

}

function addChapterVideoError(error){
  return {
    type: CHAPTER_VIDEO_ADDED_ERROR,
    error: error,
  }
}

export function addChapterVideo(chapter, title, description, url){
  let token = localStorage.getItem('token') || null;
  console.log(chapter, title, description, url);
  return dispatch => {
    axios.post(`${BASE_URL}/course/chapter/video`, {
      course_id: chapter.course_id,
      chapter_id: chapter.id,
      title: title,
      description: description,
      url: url
    }, { 'headers': { 'Authorization': "Bearer "+token } }).then((response)=>{
      dispatch(addChapterVideoSuccess(chapter, response.data.video))
    }).catch((error)=>{
      dispatch(addChapterVideoError(error.response))
    })
  }
}

function chapterRemovedSuccess(chapter_id){
  return dispatch => {
    dispatch({
      type: CHAPTER_REMOVED_SUCCESS,
      chapter_id: chapter_id
    })
  }
}

function chapterRemovedError(error){
  return dispatch => {
    dispatch({
      type: CHAPTER_REMOVED_ERROR,
      error: error
    })
  }
}

export function deleteChapter(chapter_id){
  let token = localStorage.getItem('token') || null;
  return dispatch => {
    axios.delete(`${BASE_URL}/course/chapter/${chapter_id}`, { 'headers': { 'Authorization': "Bearer "+token } }).then((response)=>{
      dispatch(chapterRemovedSuccess(chapter_id))
      window.location.reload();
    }).catch((error)=>{
      dispatch(chapterRemovedError(error.response))
    })
  }
}

function chapterUpdateSuccess(slug, chapterid){
  return dispatch => {
    dispatch({
      type: CHAPTER_UPDATE_SUCCESS,
    })
    dispatch(getCourse(slug, chapterid))
  }
}

function chapterUpdateError(error){
  return dispatch => {
    dispatch({
      type: CHAPTER_UPDATE_ERROR,
      error: error
    })
  }
}


export function updateChapter(slug, chapter_id, name, content, order, lockstatus){
  let token = localStorage.getItem('token') || null;
  return dispatch => {
    axios.post(`${BASE_URL}/course/chapter/update`, {
      slug,
      chapter_id,
      name,
      order,
      content,
      locked: lockstatus
    }, { 'headers': { 'Authorization': "Bearer "+token } }).then((response)=>{
      dispatch(chapterUpdateSuccess(slug, chapter_id))
    }).catch((error)=>{
      dispatch(chapterUpdateError(error.response.data))
    })
  }
}


function chapterVideoRemoveSuccess(videoid){
  return dispatch => {
    dispatch({
      type: CHAPTER_VIDEO_REMOVE_SUCCESS,
      video_id: videoid
    })
  }
}

function chapterVideoRemoveError(error){
  return dispatch => {
    dispatch({
      type: CHAPTER_VIDEO_REMOVE_ERROR,
      error: error
    })
  }
}

export function removeChapterVideo(video_id){
  let token = localStorage.getItem('token') || null;
  return dispatch => {
    axios.delete(`${BASE_URL}/course/chapter/video/${video_id}`,
       { 'headers': { 'Authorization': "Bearer "+token } }).then((response)=>{
      dispatch(chapterVideoRemoveSuccess(video_id))
    }).catch((error)=>{
      console.log('video error', error);
      dispatch(chapterVideoRemoveError(error.response))
    })
  }
}

export function getChapterById(chapter){
  return dispatch => {
    dispatch(loadChapterContent(chapter))
  }
}

export function updateChapterContent(chapter, newContent){
  let token = localStorage.getItem('token') || null;
  return dispatch => {
    console.log(chapter, newContent);
    axios.put(`${BASE_URL}/course/chapter/${chapter.id}`, {
      title: chapter.title,
      content: newContent,
      modified_at: chapter.modified_at,
      locked: chapter.locked,
      corder: chapter.corder
    }, { 'headers': { 'Authorization': "Bearer "+token } }).then((response)=>{
      // chapter updated
      let new_chapter = chapter;
      new_chapter.content = newContent
      dispatch(loadChapterContent(new_chapter))
    }).catch((error)=>{
      console.log(error);
    })
  }
}

export function updateChapterLock(chapter, newLock){
  let token = localStorage.getItem('token') || null;
  return dispatch => {
    axios.put(`${BASE_URL}/course/chapter/${chapter.id}`, {
      title: chapter.title,
      content: chapter.content,
      modified_at: chapter.modified_at,
      locked: newLock,
      corder: chapter.corder
    }, { 'headers': { 'Authorization': "Bearer "+token } }).then((response)=>{
      // chapter updated
      let new_chapter = chapter;
      new_chapter.locked = newLock;
      dispatch(loadChapterContent(new_chapter))
    }).catch((error)=>{
      console.log(error);
    })
  }
}
export function loadChapterContent(chapter){
  return {
    type: CHAPTER_LOAD_CONTENT,
    chapter
  }
}
