import i18n from 'i18next';
import en from './en/translation.json';
import hi from './hi/translation.json';
import ta from './ta/translation.json';
import te from './te/translation.json';


import { initReactI18next } from 'react-i18next';
export const availableLang = [
    { value: 'hi', label: 'हिन्दी' },
    { value: 'en', label: 'English' },
    { value: 'ta', label: 'Tamil' },
    // { value: 'te', label: 'Telugu' },
];
export const resources = {
    en: {
        translation: en
    },
    hi: {
        translation: hi
    },
    ta: {
        translation: ta
    },
    te: {
        translation: te
    },
} as const;

i18n.use(initReactI18next).init({
    debug: true,
    lng: navigator.language || 'en',
    resources,
});


export const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
        .then(() => {
            console.log('lang updated!');
        })
        .catch((err) => {
            console.log('Failed to update lang!');
        })
}