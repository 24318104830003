import { v4 as uuidv4 } from 'uuid';
import React, {useState, useEffect} from 'react';
import './style.scss';
import axios from 'axios';
import {BASE_URL} from './../../config';
import ReactPlayer from 'react-player';
import store from '../../store';
import {  useDispatch } from 'react-redux';
import {
   addChapterVideo
 } from './../../actions/course';

let stream, recorder, blob_payload, chunks;

function ScreenRecorder(){
  const state = store.getState();
  const dispatch = useDispatch();
  const [isUploading, set_isUploading] = useState(false);
  const [uploadPercent, set_uploadPercent] = useState(0);
  const [uploadVideoTitle] = useState("");
  const [uploadVideoDescription] = useState("");
  const [message] = useState("Record Screen");
  const [isRecording, set_isRecording] = useState(false);
  const [videoBlob, set_videoBlob] = useState(null);

  useEffect(()=>{

  }, [])

  function save_video(){
    const fileName = uuidv4()+".webm";
    axios.post(`${BASE_URL}/upload/s3signurl`, {
      filename: fileName,
      filetype: "video/webm",
    })
    .then((response)=>{
      axios.put(response.data.url,blob_payload,{
        "headers": {
          "content-type": undefined,
        },
        onUploadProgress: function(progressEvent) {
           let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
           console.log(percentCompleted);
           set_isUploading(true)
           set_uploadPercent(percentCompleted)
           if (percentCompleted === 100){
             set_isUploading(false)
             set_uploadPercent(0)
           }
       }
      })
      .then((response)=>{
        axios.get(`${BASE_URL}/upload/s3signurl?file=`+fileName)
        .then((response)=>{
          console.log(response.data);
          dispatch(addChapterVideo(state.course.mychapter, uploadVideoTitle, uploadVideoDescription, response.data.url));
        })
      })
    })
  }

  async function record() {
    if (isRecording){
      console.log(recorder);
      recorder.stop();
      stream.getVideoTracks()[0].stop();
      save_video();
    }else{
      stream = await navigator.mediaDevices.getDisplayMedia({
        video: { mediaSource: "screen" }
      });
      const audioStream = await navigator.mediaDevices.getUserMedia({audio: true}).catch(e => {throw e});
      const audioTrack = audioStream.getAudioTracks()[0];
      const videoTrack = stream.getVideoTracks()[0];
      console.log(audioTrack, videoTrack);
      stream = new MediaStream([videoTrack, audioTrack]);
      recorder = new MediaRecorder(stream);
      set_videoBlob(stream);
      set_isRecording(true);
      chunks = [];
      recorder.ondataavailable = e => chunks.push(e.data);

      recorder.onstop = e => {
        const completeBlob = new Blob(chunks, { type: chunks[0].type });
        console.log("completeBlob", completeBlob);
        blob_payload = completeBlob;
        let object_url = URL.createObjectURL(completeBlob);
        set_isRecording(false);
        set_videoBlob(null);
        set_videoBlob(object_url);
      };

      recorder.start();
    }
  }



  return (
    <div className="screen-recorder-container">
      {isUploading?(
        <div style={{color: 'white'}}>Uploading {uploadPercent}% Please Wait ....</div>
      ):(<></>)}
      {isRecording?(
        <button className="btn btn-primary" onClick={()=>record()}>Stop Recording</button>
      ):(
        <button className="btn btn-primary" onClick={()=>record()}>{message}</button>
      )}
      <div className="recording">
        <ReactPlayer id="recorder-preview-screen" playing={true} loop={true} volume={0} controls={true} url={videoBlob} />
      </div>
    </div>
  )
}

export default ScreenRecorder;
