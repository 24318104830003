
import {

  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,

  TOKEN_MISSING,
  TOKEN_VERIFY,

  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,

  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE

 } from '../actions/auth'

const defaultState = {
  loginError: '',
  isFetching: false,
  isAuthenticated: false,
  user: '',
  token: '',
  error: ''
}

const auth = (state = defaultState, action) => {

  switch (action.type) {

    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false
      });

    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        user: action.user,
        token: action.token
      });

    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        loginError: action.error
      });

    case TOKEN_MISSING:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        loginError: action.error
      });
    case TOKEN_VERIFY:
      state.isAuthenticated = true;
      state.token = action.token;
      return {...state}

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticated: false,
        user: '',
        token: ''
      });
    case SIGNUP_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false
      });
    case SIGNUP_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        user: action.user,
        token: action.token
      });
    case SIGNUP_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        error: action.error.message
      });
    case UPDATE_PASSWORD_REQUEST:
      return {...state}
    case UPDATE_PASSWORD_SUCCESS:
      return {...state}
    case UPDATE_PASSWORD_FAILURE:
      return {...state}

    default:
      return state;

  }
}

export default auth;
