import React, { Fragment, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { BASE_URL } from '../../../../config';
import { useState } from 'react';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
    scales: {
        y: {
            ticks: {
                callback: (value, index, ticks) => {
                    return `${value} Views`
                }
            }
        }
    },
    elements: {
        point: {
            radius: 0
        }
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
        },
        title: {
            display: false,
            text: 'Course Analytics',
        },
    },
};

interface AnalyticsResponseType {
    browser: string;
    created_at: string;
    device: string;
    course_id: string;
    title: string;
};

interface ChartDataSetType {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
};

interface chartDataType {
    labels: string[];
    datasets: ChartDataSetType[]
};

const CourseAnalytics = () => {
    const [labels, setLabels] = useState<string[]>([]);
    const [chartLoaded, setChartLoaded] = useState(false);
    const [graphData, setGraphData] = useState<chartDataType>({
        labels: [],
        datasets: []
    });
    
           
    const checkLabelExist = (datasets: ChartDataSetType[], label: string) => {
        datasets.forEach((data) => {
            if (data.label === label) {
                return true;
            }
        });
        return false;
    }
    const transformAnalyticsResponse = (result: AnalyticsResponseType[]) => {
        const datasets: ChartDataSetType[] = [];
        for (const data of result) {
            console.log(checkLabelExist(datasets, data.title), data.title);
            
            if (!checkLabelExist(datasets, data.title)) {
                console.log('adding label', data.title);
                
                datasets.push({
                    label: data.title,
                    data: [],
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                });
            }
        }

        setGraphData({
            labels,
            datasets
        })

        setChartLoaded(true);

    }
    const getAnalytics = async (filter, page) => {
        if (filter === "day") {
            const labelArr = [];
            for (let hour = 0; hour <= 24; hour++) {
                if (hour === 24) {
                    labelArr.push(`00:00`);
                } else {
                    labelArr.push(`${hour}:00`);
                }
            }
            setLabels(labelArr);
        }
        if (filter === "month") {
            const labelArr = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            setLabels(labelArr);
        }

        if (filter === "year") {
            const labelArr = [];
            let year = new Date().getFullYear();
            let lastYear = year + 5;
            for (year; year <= lastYear; year++) {
                labelArr.push(year);
            }
            setLabels(labelArr);
        }

        const token = localStorage.getItem('token') || null;
        const header = { 'headers': { 'Authorization': "Bearer " + token } };

        axios.get(`${BASE_URL}/analytics/course?filter=${filter}&page=${page}`, header)
            .then((response) => {
                console.log(response.data.result);
                transformAnalyticsResponse(response.data.result);
            });
    }

    useEffect(() => {
        getAnalytics("month", 1);
                // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


    return (
        <Fragment>
            {chartLoaded && <Line options={options} data={graphData} />}
        </Fragment>
    );
}

export default CourseAnalytics;

