import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { useSwipeable } from "react-swipeable";
import 'github-markdown-css';
import 'react-markdown-editor-lite/lib/index.css';
import ReactPlayer from 'react-player';
import moment from 'moment';
import axios from 'axios';
import { BASE_URL } from './../../config';
import { Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './style.scss';

import { connect, useSelector, useDispatch } from 'react-redux';

import {
  getCourse,
  
  
  getChapterById,
  
  
  updateChapterContent,
  createChapter,
  deleteChapter,
  
  addChapterVideo,
  removeChapterVideo,
  previewCourse,
  
} from './../../actions/course';

import store from '../../store';
import WebcamRecorder from './../../components/webcamera-recorder';
import ScreenRecorder from './../../components/screen-recorder';

import InviteCourseModel from './../../components/models/invite-course';
import UpdateCourseModel from './../../components/models/edit-course';
import DefaultCardImage from './../../assets/default-course-card-img.png';
import lockIconImg from './../../assets/padlock.svg';
import lockUnlockIconImg from './../../assets/padlock-unlock.svg';
import TinymceEditor from '../../components/tinymce-editor';









function CoursePage({ match }) {

  const state = store.getState();
  const dispatch = useDispatch();
  const [sidebarX, set_sidebarX] = useState(-98)
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      console.log('swipe left');
      set_sidebarX(-100)
    },
    onSwipedRight: () => {
      console.log('swipe right');
      set_sidebarX(0)
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const [show, setShow] = useState(false);
  const [showInviteModel, set_showInviteModel] = useState(false);
  const handleClose = () => setShow(false);
  const handleInviteClose = () => set_showInviteModel(false);

  const handleShow = () => setShow(true);
  const handleInvite = () => set_showInviteModel(true);
  const [showVideoRecordingSection, set_showVideoRecordingSection] = useState(false);
  const [addNewChapter, setAddNewChapter] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [ setContent] = useState("");
  const [editorValue, setEditorValue] = useState("");
  const [uploadVideoTitle, setUploadVideoTitle] = useState("Video title goes here");
  const [uploadVideoDescription, setUploadVideoDescription] = useState("Video description goes here");
  const [videoUploadProgress, setVideoUploadProgress] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadVideofileScreen, setUploadVideoFileScreen] = useState('none');
  const [ set_course_analytics] = useState({
    "apply_count": 0,
    "purchased_count": 0
  });

  useEffect(() => {

    let token = localStorage.getItem('token') || null;
    dispatch(getCourse(match.params.slug));

    fetch(`${BASE_URL}/course/analytics?course_id=${match.params.slug}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token,
      }
    }).then((response) => response.json())
      .then((data) => {
        set_course_analytics(data);
      })
  }, [dispatch, match.params.slug, set_course_analytics]);
 // eslint-disable-next-line
  function handleImageUpload(file) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = data => {
        let url = data.target.result;
        let fileName = Math.random().toString(36).substring(7) + "-" + file.name;
        let filetype = file.type;
        fetch(url)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], fileName, { type: filetype })
            axios.post(`${BASE_URL}/upload/s3signurl`, {
              filename: fileName,
              filetype: filetype
            })
              .then((response) => {
                console.log(response.data);
                 // eslint-disable-next-line
                const FILE_UPLOAD_URL = response.data.url;
                axios.put(response.data.url, file, {
                  "headers": {
                    "content-type": undefined,
                  }
                })
                  .then((response) => {
                    axios.get(`${BASE_URL}/upload/s3signurl?file=` + fileName)
                      .then((response) => {
                        console.log(response.data.url);
                        resolve(response.data.url)
                      })
                  })
              })
          })

      };
      reader.readAsDataURL(file);
    });
  };

  function onVideoUpload(event) {
    let file = event.target.files[0];
    let fileName = Math.random().toString(36).substring(7) + "-" + event.target.files[0].name;
    let fileType = event.target.files[0].type;
    axios.post(`${BASE_URL}/upload/s3signurl`, {
      filename: fileName,
      filetype: fileType
    })
      .then((response) => {
        console.log(response.data);
         // eslint-disable-next-line
        const FILE_UPLOAD_URL = response.data.url;
        axios.put(response.data.url, file, {
          "headers": {
            "content-type": undefined,
          },
          onUploadProgress: function (progressEvent) {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setUploadInProgress(true);
            setVideoUploadProgress(percentCompleted)
            if (percentCompleted === 100) {
              setUploadInProgress(false);
              setUploadVideoFileScreen('none');
            }
          }
        })
          .then((response) => {
            axios.get(`${BASE_URL}/upload/s3signurl?file=` + fileName)
              .then((response) => {
                dispatch(addChapterVideo(state.course.mychapter, uploadVideoTitle, uploadVideoDescription, response.data.url));
              })
          })
      })
  }
   // eslint-disable-next-line
  function handleEditorChange({ html, text }) {
    setContent(text);
    setEditorValue(text)
  }
 // eslint-disable-next-line
  function timestampToString(timestamp) {
    let d = new Date(timestamp);
    return moment(d).fromNow();
  }

  const isAuthenticated = useSelector(state => {
    return state.auth.isAuthenticated
  })

  const my_course = useSelector(state => {
    return state.course.course
  })
   // eslint-disable-next-line
  const isCourseApplied = useSelector(state => {

    if (state.course.course.id !== undefined) {
      if ((!state.course.course.admin && !state.course.course.has_subscription) || !state.course.course.applied) {
        window.location = `/course/view/${state.course.course.id}`;
      }
    }

    return state.course.course.applied
  })

  const publishCourse = (course_id) => {
    let token = localStorage.getItem('token') || null;
    axios.post(`${BASE_URL}/publish`, {
      'course_id': course_id,
      'type': "course"
    },
      {
        'headers': {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + token,
        }
      })
  }

  return (
    <div {...handlers}>
      <MetaTags>
        <title>{`${state.course.course.title || 'loading...'} Course`}</title>
        <meta name="description" content={state.course.course.description} />
        <meta property="og:title" content={state.course.course.title} />
        <meta property="og:image" content={state.course.course.social_image} />
      </MetaTags>
      <InviteCourseModel course={my_course} show={showInviteModel} handleShow={handleInvite} handleClose={handleInviteClose} />
      <UpdateCourseModel course={my_course} match={match} show={show} handleShow={handleShow} handleClose={handleClose} />
      <div className="container-fluid" id="course-section">
        <div className="row top-strip">
          <div className="col-md-12 page-heading">
            {state.course.course ? (
              <>
                <div>
                  <div>
                    {state.course.course.admin ? (
                      <>
                        <Link to="/dashboard">Back to Dashboard</Link>
                        <span style={{ color: "white" }}>&nbsp;|&nbsp;</span>
                        <button className="btn btn-success dashboard-nav-btn" onClick={handleShow}>Edit</button>
                        <span style={{ color: "white" }}>&nbsp;|&nbsp;</span>
                        {/* <button className="btn btn-success dashboard-nav-btn" onClick={handleInvite}>Invite</button> */}
                        {/* <span style={{color: "white"}}>&nbsp;|&nbsp;</span> */}
                        <button className="btn btn-success dashboard-nav-btn" onClick={(e) => dispatch(previewCourse())}>User Preview</button>
                        <span style={{ color: "white" }}>&nbsp;|&nbsp;</span>
                        <a href={"/course/view/" + match.params.slug} className="btn btn-success dashboard-nav-btn">Public Preview</a>
                        <span style={{ color: "white" }}>&nbsp;|&nbsp;</span>
                        <a href='#0' onClick={() => publishCourse(state.course.course.key)} className="btn btn-success dashboard-nav-btn">Publish</a>
                        {/* <span className="analytics">Applied: {course_analytics.apply_count} | Enrolled : {course_analytics.purchased_count}</span> */}
                      </>
                    ) : (<></>)}

                    {isAuthenticated && !state.course.course.admin ? (
                      <Link to="/dashboard">Back to Dashboard</Link>
                    ) : (<></>)}




                  </div>
                  <div className="title-description-section">
                    <div className="course-card">
                      <img alt='fces' src={state.course.course.card_image || DefaultCardImage} />
                    </div>
                    <div className="course-info">
                      <h1>{state.course.course.title}</h1>
                      <p>{state.course.course.goal}</p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <div className="loading-img">
                  <Spinner animation="border" variant="light" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row chapters-section-container">
          {(state.course.course.admin || state.course.course.has_subscription) ? (
            <>
              <div className="chapters-section" style={{ left: sidebarX + "%" }}>
                <ul>
                  {state.course.course ? (
                    state.course.course.chapters
                      .sort((a, b) => a.corder > b.corder ? 1 : -1)
                      .map((e, index) => {
                        return <li className={state.course.mychapter && e.id === state.course.mychapter.id ? 'active' : ''} key={e.id} onClick={() => dispatch(getChapterById(e))}>
                          <div>
                            {state.course.course.admin ? (
                              <img alt='Lockicon-img' className="lock-icon" src={e.locked ? lockIconImg : lockUnlockIconImg} />
                            ) : (<></>)}
                            {e.title}
                          </div>
                        </li>
                      })
                  ) : (<></>)
                  }
                  {state.course.course.admin ? (
                    <li>
                      <input type="text" value={addNewChapter} onChange={(e) => setAddNewChapter(e.target.value)} placeholder="Title" />
                      <button disabled={addNewChapter === ""} className="btn btn-primary" onClick={() => {
                        if (addNewChapter !== "") {
                          dispatch(createChapter(match.params.slug, { course_id: match.params.slug, title: addNewChapter }))
                        }
                        setAddNewChapter("");
                      }} style={{ marginTop: "5px" }}>Add</button>
                    </li>
                  ) : (<></>)}
                </ul>
              </div>
              <div className="summary-section">
                <div className="chapter-info-header">
                  {state.course.mychapter ? (
                    <>
                      <div>
                        <div>
                          <h1>{state.course.mychapter.title}</h1>

                        </div>
                        <div className="chapter-strip-action">
                          {state.auth.isAuthenticated && state.course.course.admin ? (
                            <>
                              <button className="btn btn-primary" style={{
                                marginLeft: '5px'
                              }} onClick={(e) => {
                                dispatch(deleteChapter(state.course.mychapter.id))
                              }}>Remove</button>
                              <button className="btn btn-primary" style={{
                                marginLeft: '5px'
                              }} onClick={() => set_showVideoRecordingSection(!showVideoRecordingSection)}>{showVideoRecordingSection ? "Hide Uploader/Recorder" : "Upload/Record Video"}</button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (<></>)}

                </div>
                <div className="main-section-container">

                  {state.course.mychapter ? (
                    <div className="chapter-content-section">
                      {editMode ? (
                        <>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row'
                          }}>
                            <button className="btn btn-primary" style={{
                              marginLeft: '0px',
                              marginBottom: '5px'
                            }} onClick={(e) => {
                              setEditMode(false)
                              dispatch(updateChapterContent(state.course.mychapter, editorValue))
                            }}>Save</button>
                            <button className="btn btn-primary" style={{
                              marginLeft: '5px',
                              marginBottom: '5px'
                            }} onClick={(e) => {
                              setEditMode(false)
                            }}>Cancel</button>
                          </div>
                          <TinymceEditor value={state.course.mychapter.content} onChange={(data) => setEditorValue(data)} />
                        </>
                      ) : (
                        <>
                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '20px 0px'
                          }}>
                            {state.auth.isAuthenticated && state.course.course.admin ? (
                              <>
                                <button className="btn btn-primary" onClick={(e) => {
                                  setEditMode(true);
                                  setEditorValue(state.course.mychapter.content)
                                }}>
                                  Edit
                                </button>
                              </>
                            ) : (<></>)}
                          </div>
                          <div className="markdown-body">
                            {state.course.mychapter ? (
                              <div dangerouslySetInnerHTML={{ __html: state.course.mychapter.content }} />
                            ) : (<></>)}
                          </div>
                          {/* {state.course.mychapter && <Discussion course_id={state.course.mychapter.course_id} chapter_id={state.course.mychapter.id} />} */}
                        </>
                      )}
                    </div>
                  ) : (<></>)}

                  <div className="videos-section">
                    <div className="videos-section-container">
                      {state.course.mychapter && state.course.mychapter.videos ? (
                        state.course.mychapter.videos.map((v) => (
                          <>
                            <div className="video" style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}>
                              <h1>{v.title}</h1>
                              <ReactPlayer volume={0.8} controls={true} url={v.url} />
                              <p>{v.description}</p>
                              {state.course.course.admin ? (
                                <button className="btn btn-primary" onClick={() => dispatch(removeChapterVideo(v.id))}>Remove</button>
                              ) : (<></>)}
                            </div>
                          </>
                        ))
                      ) : (
                        <></>
                      )}
                      <>

                        {state.auth.isAuthenticated && state.course.course.admin && state.course.course.chapters.length > 0 && state.course.mychapter && showVideoRecordingSection ? (
                          <>
                            <div className="video-record-upload-action-bar">
                              <button className="btn btn-primary upload-new-video-btn" onClick={(e) => {
                                setUploadVideoFileScreen('flex')
                              }}>Upload new video</button>
                              <h1>OR</h1>
                              <div className="recording-section">
                                <WebcamRecorder />
                                <ScreenRecorder />
                              </div>
                            </div>

                          </>
                        ) : (<></>)}
                        <div className="upload-video-container" style={{
                          display: uploadVideofileScreen,

                        }}>

                          {uploadInProgress ? (
                            <>
                              <div style={{
                                width: videoUploadProgress + '%',
                                height: '15px',
                                background: '#8bc34a',
                                fontSize: '12px',
                                color: 'white',
                                padding: '5px 5px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                transition: 'width 1.5s ease-in',
                                margin: '5px 0px'
                              }}>Uploading...{videoUploadProgress}%</div>
                            </>
                          ) : (
                            <>
                              <div className="form-group">
                                <label style={{ color: 'white' }}>Video Title</label>
                                <input className="form-control" type="text" value={uploadVideoTitle} onChange={(e) => {
                                  setUploadVideoTitle(e.target.value)
                                }} />
                              </div>
                              <div className="form-group">
                                <label style={{ color: 'white' }}>Video Description</label>
                                <textarea className="form-control" value={uploadVideoDescription} type="text" onChange={(e) => {
                                  setUploadVideoDescription(e.target.value)
                                }}></textarea>
                              </div>
                              <input type="file" id="videoUploadBtn" hidden onChange={onVideoUpload} />
                              <label className="btn btn-primary" htmlFor="videoUploadBtn">Browse video from system</label>
                              <button className="btn btn-primary" onClick={
                                (e) => {
                                  setUploadVideoFileScreen('none');
                                }
                              }>Cancel</button>
                            </>
                          )}
                        </div>

                      </>

                    </div>

                  </div>

                </div>
              </div>
            </>
          ) : (
            <>
            </>
          )}

        </div>


      </div>
    </div>
  )
}
function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
export default connect(mapStateToProps)(CoursePage);
