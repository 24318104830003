import React, { useEffect } from 'react';
import './style.scss';
import { Navbar, Nav, NavDropdown} from 'react-bootstrap';
import logoSamll from './../../assets/guruji-logo-new.png';
import { useSelector, useDispatch } from 'react-redux';
import {
  logoutUser
} from './../../actions/auth';

import {
  fetchUserWithToken
} from './../../actions/user';
import { SelectLanguage } from './../language';
import { useTranslation } from 'react-i18next';

function NavbarGlobal({ activePage }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(state => {
    return state.auth.isAuthenticated
  })

  const token = useSelector(state => {
    return state.auth.token
  })

  const user = useSelector(state => {
    return state.user.user
  })

  useEffect(() => {
    
    dispatch(fetchUserWithToken(token))
  },[token, dispatch])

  return (

    <>
      <Navbar className="col-md-12" expand="lg">
        <Navbar.Brand href="/">
          <img className="brand-logo" alt='Small-Logo' src={logoSamll} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">

          </Nav>
          <Nav.Link>
            <SelectLanguage />
          </Nav.Link>
          {!isAuthenticated ? (
            <Nav.Link href="/login">{t('navbarLogin')}</Nav.Link>
          ) : (<></>)}

          {isAuthenticated ? (
            <>
              {/* <Nav.Link className={activePage==="playground"?"selected":""} href="/playground">Kids Playground (Beta)</Nav.Link> */}
              <Nav.Link className={activePage === "dashboard" ? "selected" : ""} href="/dashboard">{t('navbarDashboard')}</Nav.Link>
              <NavDropdown title={t('navbarUserGreeting') + user.first_name || "My Account"} id="my-account-nav-dropdown">
                <NavDropdown.Item href={"/instructor/" + user.id}>Profile</NavDropdown.Item>
                <NavDropdown.Item href="#" onClick={() => dispatch(logoutUser())}>Logout</NavDropdown.Item>
              </NavDropdown>
            </>
          ) : (<></>)}
        </Navbar.Collapse>
      </Navbar>
    </>
  );

}

export default NavbarGlobal;
