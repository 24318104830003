import React, {useEffect} from 'react';
import './style.scss';
import history from './../../utils/history';
import { useDispatch} from 'react-redux';
import parseParams from './../../utils/parse-query-params';
import loadingImage from './../../assets/loading.svg';
import {BASE_URL} from './../../config';

import {
  coursePayment
} from './../../actions/course';

function ConfirmPayment({match, location}){
  const dispatch = useDispatch();
  useEffect(()=>{
    const params_data = parseParams(location.search);
    if (params_data.type === "wallet"){
      let token = localStorage.getItem('token') || null;
      fetch(`${BASE_URL}/wallet/confirmpayment`,{
        method: "POST",
        body: JSON.stringify(params_data),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+token,
         }
      }).then((response)=>response.json())
      .then((data)=>{
        history.push("/dashboard");
        window.location.reload();
      }).catch((error)=>{
        alert(`payment failed, your payment id : ${params_data.payment_request_id}. contact support@learnwithguruji.com`)
        history.push("/");
        window.location.reload();
      })
    }else{
      dispatch(coursePayment(params_data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <div className="row" style={{background: 'white'}}>
      <div className="col-md-12 processing-payment">
        <h1>Processing your payment ... please wait.</h1>
        <img src={loadingImage} alt="poto" />
        <p></p>
      </div>
    </div>
  )
}

export default ConfirmPayment;
