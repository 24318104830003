import React, { useEffect, Fragment, useState } from 'react';
import ReactPlayer from 'react-player';
import './style.scss';
import { useSelector, useDispatch } from 'react-redux';
import MetaTags from 'react-meta-tags';
import Navbar from './../../components/navbar';
import FeatureBanner from './../../components/featured-banner';
import GoogleLoginButton from './../../components/google-button';
import Footer from './../../components/footer';
import DefaultCardImage from './../../assets/default-course-card-img.png';
import SignupPage from './../signup';
import {
  getAllQuizzesPublic,
} from './../../actions/quiz';
import {
  getAllCourses,
  getFeaturedCourses,
} from './../../actions/course';



import { useTranslation } from 'react-i18next';

function HomePage() {
  const { t } = useTranslation();
  const [isCustomTheme, setIsCustomTheme] = useState(false);
  const [customThemeUrl, setCustomThemeUrl] = useState("");
  const [customThemeIframeHeight, setcustomThemeIframeHeight] = useState(0);

  const dispatch = useDispatch();




  useEffect(() => {
    dispatch(getFeaturedCourses())
    dispatch(getAllCourses({ type: "public" }))
    dispatch(getAllQuizzesPublic())
    const appName = window.location.hostname.split('.')[0];
    fetch(`https://cdn.learnwithguruji.com/apps/${appName}.json`)
      .then(resp => resp.json())
      .then((data) => {
        setIsCustomTheme(true);
        const body = document.body,
          html = document.documentElement;

        const height = Math.max(body.scrollHeight, body.offsetHeight,
          html.clientHeight, html.scrollHeight, html.offsetHeight);
        setcustomThemeIframeHeight(height);
        setCustomThemeUrl(`https://cdn.learnwithguruji.com/apps/${appName}/template/index.html`);
      })
      .catch((error) => {
        setIsCustomTheme(false);
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const quizzes = useSelector(state => state.quiz.public);
  const courses = useSelector(state => state.course.courses);
  const featuredCourses = useSelector(state => state.course.featured);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return (<Fragment>

    <div className="container-fluid home-page">
      <MetaTags> 
        <title>{`Learn New Skills Or Start Your Own Online Pathshala (Online School) | learnwithguruji.com`}</title>
        <meta name="description" content="Buy Online Courses or Start your teaching business with learnwithguruji.com. We help you start your own online teaching business with zero investment." />
        <meta name="keywords" content="Buy Online Courses, Start your teaching business, online teaching business with zero investment, sell online courses, live classes, online workshops for skill courses." />
      </MetaTags>
      <div className="row">
        <Navbar />
      </div>
      {isCustomTheme && <div className="row">
        <iframe title='Theme' style={{ width: '100%', height: customThemeIframeHeight + 'px' }} src={customThemeUrl}></iframe>
      </div>}
      {!isCustomTheme && <Fragment>
        <div className="row">
          <FeatureBanner featuredCourses={featuredCourses} />
        </div>
        <div className="row" id="main-banner">
          <div className="col-md-6 main-banner-text">
            <h1>{t('defaultTagLine')}</h1>
            <p>{t('defaultMissionLine')}</p>
            <p>{t('getStartedWithGurujiToday')}</p>
            {!isAuthenticated && <GoogleLoginButton />}
          </div>
          <div className="col-md-6 logo-image-banner" style={{ height: isAuthenticated ? "auto" : "398px" }}>
            {!isAuthenticated && <SignupPage />}
            {isAuthenticated && <ReactPlayer playing={true} loop={true} controls={false} url={"https://player.vimeo.com/video/660116334?h=462208c360"} />}
          </div>
        </div>

        {courses && courses.length > 0 ? (
          <div className="row" id="our-courses">
            <div className="container">
              <div className="row">
                <div className="col-md-12 heading">
                  <h1>EXPLORE OUR COURSES / WORKSHOPS / QUIZZES</h1>
                </div>

                {courses && courses.length > 0 ? (
                  courses.map((course, i) => {
                    return (
                      <div key={"c_" + course.id} className="col-md-4">
                        <div className="course-card">
                          <a href={"/course/view/" + course.key}>
                            <div className="cimage" style={{ background: "url(" + (course.card_image || DefaultCardImage) + ")" }}></div>
                          </a>

                          <div className="ccontent">
                            <h1 style={{ minHeight: '44px' }}>{course.title}</h1>
                            <p>{course.goal}</p>

                            <div className="cta-btn">
                              <a className="btn btn-primary buy-now" href={"/course/view/" + course.key}>
                                <div className="price">
                                  {course && course.discount > 0 && course.published && <div className="final-price">₹ {course.offer} <span><span className="strike">₹ {course.fee}</span><span className="discount">{course.discount}% OFF</span></span></div>}
                                  {course && course.published && course.discount === 0 && <div className="final-price">{course.published ? "View" : "Coming Soon"}</div>}
                                  {course && !course.published && <div className="final-price">{course.published ? "View" : "Coming Soon"}</div>}
                                </div></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (<></>)}

                {quizzes && quizzes.length > 0 && <Fragment>
                  {quizzes.map((q) => <div className="col-md-4">
                    <div className="course-card">
                      <a href={"/quiz/" + q.id}>
                        <div className="cimage" style={{ background: "url(" + (q.card_image || DefaultCardImage) + ")" }}></div>
                      </a>
                      <div className="ccontent">
                        <h1 style={{ minHeight: '44px' }}>{q.title}</h1>
                        <p>Quiz</p>
                        <div className="cta-btn">
                          <a className="btn btn-primary buy-now" href={"/quiz/" + q.id}>
                            <div className="price">Take Quiz</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>)}
                </Fragment>}
              </div>
            </div>
          </div>
        ) : (<></>)}
        <Footer />
      </Fragment>}
    </div>
  </Fragment>);
}

export default HomePage;
