import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {  Alert } from "react-bootstrap";
import "./style.scss";
import store from "../../store";
import { BASE_URL } from "./../../config";
import history from "./../../utils/history";
import GoogleLoginButton from "./../../components/google-button";
import { useForm } from "react-hook-form";

import { Link } from "react-router-dom";
import { requestLogin } from "../../actions/auth";

import logoSamll from "./../../assets/guruji-logo-new.png";
const email_regex =
  /^[-!#$%&'*+0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

function LoginPage({ props }) {
  const {
    register,
    
  } = useForm();
  let state = store.getState();
  const dispatch = useDispatch();
  const [errors, set_errors] = useState({
    username: "",
    password: "",
    error: "",
  });
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    let token = localStorage.getItem("token") || null;

    fetch(`${BASE_URL}/verifytoken`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.message === "verified") {
          const redirect_url = localStorage.getItem("redirect");
          if (redirect_url) {
            history.push(new URL(redirect_url).pathname);
            localStorage.removeItem("redirect");
            window.location.reload();
            return;
          }
          history.push("/dashboard");
          window.location.reload();
        }
      });
  }, []);

  function handleLogin() {
    if (username === "") {
      set_errors({
        username: "Email Required!",
      });
      return;
    } else if (!email_regex.test(username)) {
      set_errors({
        username: "Please enter valid email address!",
      });
      return;
    }
    if (password === "") {
      set_errors({
        password: "Password Required!",
      }) ;
      return;
    }
    
    dispatch(requestLogin({ username, password }));
  }

  function handleChange(event) {
    if (event.target.name === "username") {
      setUsername(event.target.value);
    }
    if (event.target.name === "password") {
      setPassword(event.target.value);
    }
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <a href="/" className="logo-image">
          <img src={logoSamll} alt="" />
        </a>
        <h1>Login Your Account</h1>
        <div className="google-login-button" style={{ textAlign: "center" }}>
          <GoogleLoginButton />
          <h1>Or</h1>
        </div>
        <div className="input">
          <input
            type="email"
            {...register("username", {
              required: "this field is required",
              minLength: {
                value: 5,
                message: "min string length 5",
              },
            })}
            placeholder="Email"
            onChange={handleChange}
          />

          {errors.username && (
            <Alert key="username" style={{ marginTop: "5px" }} variant="danger">
              {errors.username}
            </Alert>
          )}
        </div>
        <div className="input">
          <input
            type="password"
            {...register("password", {
              required: "this field is required",
              minLength: {
                value: 5,
                message: "min length should be 5",
              },
            })}
            placeholder="Password"
            onChange={handleChange}
          />

          {errors.password && (
            <Alert key="password" style={{ marginTop: "5px" }} variant="danger">
              {errors.password}
            </Alert>
          )}
        </div>
        <div className="login-button">
          <button
            disabled={state.auth.isFetching}
            className="btn btn-primary"
            onClick={handleLogin}
          >
            {state.auth.isFetching ? "Loading..." : "Login"}
          </button>
        </div>

        {state.auth.loginError && (
          <Alert key="error" style={{ marginTop: "5px" }} variant="danger">
            {state.auth.loginError}
          </Alert>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "5px",
          }}
        >
          <Link to="/signup">Create Account</Link>
          <a href="/forgotpassword">Forgot Password</a>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(LoginPage);
