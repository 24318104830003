import React, {useEffect, useState} from 'react';
import {Button, Modal, Form, Row, Col} from 'react-bootstrap';

import {
  createCourse
} from './../../actions/course';


import {useDispatch} from 'react-redux';


function CreateCourseModel({show, handleClose, handleShow}){

  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [slug, setSlug] = useState("");
  const [visible, setVisible] = useState(true);
  const [fee, setFee] = useState(0);
  const [goal, setGoal] = useState("");




  useEffect(()=>{

  }, [show, handleShow, handleClose])


  function handleCreate(){
    const course = {
      title: title,
      description: description,
      slug: slug,
      private: visible,
      fee: parseInt(fee),
      goal: goal
    }
    dispatch(createCourse(course))
    handleClose();
  }

  return(
    <Modal style={{zIndex:1000000}} show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Course</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
        <Form.Group controlId="courseTitle">
          <Form.Label>Title</Form.Label>
          <Form.Control onChange={(e)=>{
            setTitle(e.target.value)
            setSlug(e.target.value.toLowerCase().replace(/\s+/g, '-'))
          }} type="text" placeholder="Title" />
          <Form.Text className="text-muted">
            Course Title Ex. Learn Cooking
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="courseDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" onChange={(e)=>setDescription(e.target.value)} rows={3} placeholder="Description" />
          <Form.Text className="text-muted">
            Enter Short Course Description here
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="courseSlug">
          <Form.Label>Slug</Form.Label>
          <Form.Control value={slug} onChange={(e)=>setSlug(e.target.value)} type="text" placeholder="Slug" />
          <Form.Text className="text-muted">
            Course Slug Ex. Learn-Cooking
          </Form.Text>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label as="legend" column sm={2}>
            Visible
          </Form.Label>
          <Col sm={10}>
            <Form.Check
              onChange={(e)=>setVisible(true)}
              type="radio"
              label="Public"
              name="courseVisibilityStatus"
              id="formHorizontalRadios1"
            />
            <Form.Check
              onChange={(e)=>setVisible(false)}
              type="radio"
              label="Private"
              name="courseVisibilityStatus"
              id="formHorizontalRadios2"
            />
          </Col>
        </Form.Group>
        <Form.Group controlId="courseGoal">
          <Form.Label>Goal</Form.Label>
          <Form.Control onChange={(e)=>setGoal(e.target.value)} type="text" placeholder="Cooking" />
          <Form.Text className="text-muted">
            Course Goal ex. Cooking, Programming, Music
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="courseFee">
          <Form.Label>Fee</Form.Label>
          <Form.Control onChange={(e)=>setFee(e.target.value)} type="number" placeholder="Fee" />
          <Form.Text className="text-muted">
            Course Fee
          </Form.Text>
        </Form.Group>
      </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleCreate}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default CreateCourseModel;
