import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { BASE_URL } from '../../config';
import axios from 'axios';

export default function TinymceEditor({ value, onChange }) {

    const editorRef = useRef(null);
    
    



    return (
        <>
            <Editor
                apiKey="6vq6b07z38vjvw1vo6yvxkjbzp1ty3ws6sxx1q62f6jgwvpt"
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={value}
                onEditorChange={(content) => onChange(content)}
                init={{
                    height: 500,
                    menubar: false,
                    images_upload_handler: function (blobInfo, success, failure) {
                        const blob = blobInfo.blob();
                        const fileName = blobInfo.filename();
                        let filetype = "image/jpeg";
                        const file = new File([blob], fileName, { type: filetype })
                        axios.post(`${BASE_URL}/upload/s3signurl`, {
                            filename: fileName,
                            filetype: filetype
                        })
                            .then((response) => {
                                console.log(response.data);
                                axios.put(response.data.url, file, {
                                    "headers": {
                                        "content-type": undefined,
                                    }
                                })
                                    .then((response) => {
                                        axios.get(`${BASE_URL}/upload/s3signurl?file=` + fileName)
                                            .then((response) => {
                                                success(response.data.url);
                                            }).catch((err) => {
                                                console.log(err);
                                                failure('Failed to fetch images from server!');

                                            })
                                    })
                            }).catch((err) => {
                                console.log(err);
                                failure('Failed to upload!');

                            })
                    },
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                        'table'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | image ' +
                        'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

                    content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        </>
    );
}
