import React from 'react';
import { Fragment } from 'react';
import SelectUserType from './select-user-type';
import './style.scss';
const Onboarding = () => {
    return (
        <Fragment>
            <div id="onboarding">
                <SelectUserType />
            </div>
        </Fragment>
    )
};

export default Onboarding;