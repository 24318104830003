import axios from 'axios';
import {BASE_URL} from './../config';

export const CREATE_QUIZ_REQUEST = 'CREATE_QUIZ_REQUEST';
export const CREATE_QUIZ_SUCCESS = 'CREATE_QUIZ_SUCCESS';
export const CREATE_QUIZ_ERROR = 'CREATE_QUIZ_ERROR';

export const UPDATE_QUIZ_REQUEST = 'UPDATE_QUIZ_REQUEST';
export const UPDATE_QUIZ_SUCCESS = 'UPDATE_QUIZ_SUCCESS';
export const UPDATE_QUIZ_ERROR = 'UPDATE_QUIZ_ERROR';

export const DELETE_QUIZ_REQUEST = 'DELETE_QUIZ_REQUEST';
export const DELETE_QUIZ_SUCCESS = 'DELETE_QUIZ_SUCCESS';
export const DELETE_QUIZ_ERROR = 'DELETE_QUIZ_ERROR';

export const GET_QUIZ_REQUEST = 'GET_QUIZ_REQUEST';
export const GET_QUIZ_SUCCESS = 'GET_QUIZ_SUCCESS';
export const GET_QUIZ_ERROR = 'GET_QUIZ_ERROR';

export const CREATE_QUIZ_QUESTION_REQUEST = 'CREATE_QUIZ_QUESTION_REQUEST';
export const CREATE_QUIZ_QUESTION_SUCCESS = 'CREATE_QUIZ_QUESTION_SUCCESS';
export const CREATE_QUIZ_QUESTION_ERROR = 'CREATE_QUIZ_QUESTION_ERROR';

export const DELETE_QUIZ_QUESTION_REQUEST = 'DELETE_QUIZ_QUESTION_REQUEST';
export const DELETE_QUIZ_QUESTION_SUCCESS = 'DELETE_QUIZ_QUESTION_SUCCESS';
export const DELETE_QUIZ_QUESTION_ERROR = 'DELETE_QUIZ_QUESTION_ERROR';

export const UPDATE_QUIZ_QUESTION_REQUEST = 'UPDATE_QUIZ_QUESTION_REQUEST';
export const UPDATE_QUIZ_QUESTION_SUCCESS = 'UPDATE_QUIZ_QUESTION_SUCCESS';
export const UPDATE_QUIZ_QUESTION_ERROR = 'UPDATE_QUIZ_QUESTION_ERROR';

export const GET_QUIZ_QUESTION_REQUEST = 'GET_QUIZ_QUESTION_REQUEST';
export const GET_QUIZ_QUESTION_SUCCESS = 'GET_QUIZ_QUESTION_SUCCESS';
export const GET_QUIZ_QUESTION_ERROR = 'GET_QUIZ_QUESTION_ERROR';

export const CREATE_QUIZ_SUBMISSION_REQUEST = 'CREATE_QUIZ_SUBMISSION_REQUEST';
export const CREATE_QUIZ_SUBMISSION_SUCCESS = 'CREATE_QUIZ_SUBMISSION_SUCCESS';
export const CREATE_QUIZ_SUBMISSION_ERROR = 'CREATE_QUIZ_SUBMISSION_ERROR';

export const QUIZ_APPLY_REQUEST = 'QUIZ_APPLY_REQUEST';
export const QUIZ_APPLY_SUCCESS = 'QUIZ_APPLY_SUCCESS';
export const QUIZ_APPLY_ERROR = 'QUIZ_APPLY_ERROR';

export const GET_ALL_QUIZZES_REQUEST = 'GET_ALL_QUIZZES_REQUEST';
export const GET_ALL_QUIZZES_SUCCESS = 'GET_ALL_QUIZZES_SUCCESS';
export const GET_ALL_QUIZZES_ERROR = 'GET_ALL_QUIZZES_ERROR';

export const GET_SUBMISSIONS_REQUEST = 'GET_SUBMISSIONS_REQUEST';
export const GET_SUBMISSIONS_SUCCESS = 'GET_SUBMISSIONS_SUCCESS';
export const GET_SUBMISSIONS_ERROR = 'GET_SUBMISSIONS_ERROR';

export const GET_ALL_QUIZZES_PUBLIC_REQUEST = 'GET_ALL_QUIZZES_PUBLIC_REQUEST';
export const GET_ALL_QUIZZES_PUBLIC_SUCCESS = 'GET_ALL_QUIZZES_PUBLIC_SUCCESS';
export const GET_ALL_QUIZZES_PUBLIC_ERROR = 'GET_ALL_QUIZZES_PUBLIC_ERROR';

export function getQuiz(quiz_id){
  return dispatch => {

    let token = localStorage.getItem('token') || null;

    dispatch({
      type: GET_QUIZ_REQUEST,
      quiz: []
    })
    axios.get(`${BASE_URL}/quiz/${quiz_id}`, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: GET_QUIZ_SUCCESS,
         message: "Quiz Get Success",
         quiz: response.data
       })
     })
     .catch((error)=>{
       dispatch({
         type: GET_QUIZ_ERROR,
         message: "Quiz Get Failed!",
         quiz: []
       })
     })
  }
}

export function getAllQuizzes(){

  return dispatch => {

    let token = localStorage.getItem('token') || null;

    dispatch({
      type: GET_ALL_QUIZZES_REQUEST,
      quizzes: []
    })

    axios.get(`${BASE_URL}/quiz`, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: GET_ALL_QUIZZES_SUCCESS,
         quizzes: response.data
       })
     })
     .catch((error)=>{
       dispatch({
         type: GET_ALL_QUIZZES_ERROR,
         quizzes: []
       })
     })
  }
}

export function getAllQuizzesPublic(){

  return dispatch => {

    let token = localStorage.getItem('token') || null;

    dispatch({
      type: GET_ALL_QUIZZES_PUBLIC_REQUEST,
      quizzes: []
    })

    axios.get(`${BASE_URL}/quiz/public`, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: GET_ALL_QUIZZES_PUBLIC_SUCCESS,
         quizzes: response.data
       })
     })
     .catch((error)=>{
       dispatch({
         type: GET_ALL_QUIZZES_PUBLIC_ERROR,
         quizzes: []
       })
     })
  }
}


export function applyQuiz(quiz_id){
  return dispatch => {

    let token = localStorage.getItem('token') || null;

    dispatch({
      type: QUIZ_APPLY_REQUEST
    })
    axios.post(`${BASE_URL}/quiz/${quiz_id}/apply`, {}, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: QUIZ_APPLY_SUCCESS,
         message: "Quiz Applied"
       })
     })
     .catch((error)=>{
       dispatch({
         type: QUIZ_APPLY_ERROR,
         message: "Quiz Apply Failed!"
       })
     })
  }
}

export function createQuiz(quiz){
  return dispatch => {

    let token = localStorage.getItem('token') || null;

    dispatch({
      type: CREATE_QUIZ_REQUEST
    })
    axios.post(`${BASE_URL}/quiz`, quiz, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: CREATE_QUIZ_SUCCESS,
         message: "Quiz Created"
       })
     })
     .catch((error)=>{
       dispatch({
         type: CREATE_QUIZ_ERROR,
         message: "Quiz Creation Failed!"
       })
     })
  }
}


export function createQuizQuestion(question) {

  return dispatch => {

    let token = localStorage.getItem('token') || null;

    dispatch({
      type: CREATE_QUIZ_QUESTION_REQUEST
    })
    axios.post(`${BASE_URL}/quiz/question`, question, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: CREATE_QUIZ_QUESTION_SUCCESS,
         message: "Quiz Question Created"
       })
     })
     .catch((error)=>{
       dispatch({
         type: CREATE_QUIZ_QUESTION_ERROR,
         message: "Quiz Question Creation Failed!"
       })
     })
  }

}

export function updateQuiz(quiz_id, new_quiz) {

  return dispatch => {

    let token = localStorage.getItem('token') || null;

    dispatch({
      type: UPDATE_QUIZ_REQUEST
    })
    axios.put(`${BASE_URL}/quiz/${quiz_id}`, new_quiz, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: UPDATE_QUIZ_SUCCESS,
         message: "Quiz Updated"
       })
     })
     .catch((error)=>{
       dispatch({
         type: UPDATE_QUIZ_ERROR,
         message: "Quiz Update Failed!"
       })
     })
  }

}

export function updateQuizQuestion(question_id, new_question) {

  return dispatch => {

    let token = localStorage.getItem('token') || null;

    dispatch({
      type: UPDATE_QUIZ_QUESTION_REQUEST
    })
    axios.put(`${BASE_URL}/quiz/question/${question_id}`, new_question, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: UPDATE_QUIZ_QUESTION_SUCCESS,
         message: "Quiz Question Deleted"
       })
     })
     .catch((error)=>{
       dispatch({
         type: UPDATE_QUIZ_QUESTION_ERROR,
         message: "Quiz Question Deletion Failed!"
       })
     })
  }

}

export function deleteQuizQuestion(question_id) {

  return dispatch => {

    let token = localStorage.getItem('token') || null;

    dispatch({
      type: DELETE_QUIZ_QUESTION_REQUEST
    })
    axios.delete(`${BASE_URL}/quiz/question/${question_id}`, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: DELETE_QUIZ_QUESTION_SUCCESS,
         message: "Quiz Question Deleted"
       })
     })
     .catch((error)=>{
       dispatch({
         type: DELETE_QUIZ_QUESTION_ERROR,
         message: "Quiz Question Deletion Failed!"
       })
     })
  }

}


export function createQuizSubmission(submission) {

  return dispatch => {

    let token = localStorage.getItem('token') || null;

    dispatch({
      type: CREATE_QUIZ_SUBMISSION_REQUEST
    })
    axios.post(`${BASE_URL}/quiz/question/submission`, submission, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: CREATE_QUIZ_SUBMISSION_SUCCESS,
         message: "Quiz Submission Created"
       })
     })
     .catch((error)=>{
       dispatch({
         type: CREATE_QUIZ_SUBMISSION_ERROR,
         message: "Quiz Submission Delete Failed!"
       })
     })
  }

}

export function exportQuiz(quiz_id) {
  return dispatch => {
    let token = localStorage.getItem('token') || null;
    dispatch({
      type: GET_SUBMISSIONS_REQUEST
    })
    axios.post(`${BASE_URL}/quiz/${quiz_id}/submissions`, {}, { 'headers': {
      'Content-Type': 'application/json',
      'Authorization': "Bearer "+token,
     }})
     .then((response)=>{
       dispatch({
         type: GET_SUBMISSIONS_SUCCESS,
         message: "Quiz Submissions Export Success",
         submissions: response.data
       })
     })
     .catch((error)=>{
       dispatch({
         type: GET_SUBMISSIONS_ERROR,
         message: "Quiz Submissions Export Failed!"
       })
     })
  }
}
