import React, { Fragment } from 'react';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-twilight";
const CodeEditor = ({ mode, theme, editorValue, onChange, name, style}) => {
    return (
        <Fragment>

            <AceEditor
                value={editorValue}
                mode={mode}
                theme={theme}
                onChange={onChange}
                name={name}
                editorProps={{ $blockScrolling: true }}
                style={style}
                fontSize={"16pt"}
            />
        </Fragment>
    );
}

export default CodeEditor;