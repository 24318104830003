import React, { Fragment } from "react";
import CustomVideoPlayer from "../../../components/video-player";
import { BASE_URL } from './../../../config';
import history from './../../../utils/history';

const EnableTutorAccountAccess = () => {
    const enableAccount = () => {
        let token = localStorage.getItem('token') || null;
        fetch(`${BASE_URL}/course/instructor/apply`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(response => response.json())
            .then((data) => {
                console.log(data);
                history.push('/dashboard');
                window.location.reload();
            })
    }
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-8">
                <CustomVideoPlayer src="https://www.youtube.com/embed/gn3LkvnbcUM" />
                </div>
                <div className="col-md-4">
                    <h1>Want to start your online teaching business?</h1>
                    <p>Best instructors around you teach students on Guruji. We provide the best tools to help you start your online teaching business.</p>
                    <button className="btn btn-primary" onClick={() => enableAccount()}>Create Your First Course</button>
                </div>
            </div>
        </Fragment>
    );
}

export default EnableTutorAccountAccess;