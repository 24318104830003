import React, { Fragment } from 'react';
import { useState } from 'react';
import CourseAnalytics from './course-analytics';

const Analytics = () => {
    useState(() => {

    });
    return (
        <Fragment>
            Analytics
            <CourseAnalytics />
        </Fragment>
    );
}

export default Analytics;

