import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'react-bootstrap';
import { BASE_URL } from './../../config';
import {
    getQuiz
} from './../../actions/quiz';
function EditQuiz({ quiz, question_index }) {
    const dispatch = useDispatch();
    useEffect(() => { }, [quiz, question_index]);

    function handleQuestionDelete(quiz_id, question_id) {
        let token = localStorage.getItem('token') || null;

        fetch(`${BASE_URL}/quiz/${quiz_id}/question/${question_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token,
            }
        }).then(response => response.json())
            .then((data) => {
                dispatch(getQuiz(quiz_id));
            })
    }

    return (
        <Fragment>
            <Button variant="danger" onClick={() => handleQuestionDelete(quiz.quiz.id, quiz.questions[question_index].id)}>Delete Question</Button>
        </Fragment>
    );
}

export default EditQuiz;