import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './auth'
import course from './course'
import user from './user'
import quiz from './quiz'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  course,
  user,
  quiz
})

export default createRootReducer
