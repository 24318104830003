import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import {Button, Form} from 'react-bootstrap';

import './style.scss';





import {forgotPassword} from '../../actions/auth';


function ForgotPasswordPage({match, location}){


  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  function submit(){
      dispatch(forgotPassword(email));
  }

  return (
    <div className="container password-section">
      <div className="row">
        <div className="col-md-12">
          <h1>Reset Your Password</h1>
        </div>
        <div className="col-md-12">
          <Form.Group controlId="yourEmail">
            <Form.Label>Your Email</Form.Label>
            <Form.Control onChange={(e)=>{
              setEmail(e.target.value)
            }} type="email" placeholder="Your Email" />
            <Form.Text className="text-muted">
              Enter Your Email
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="resetPasswordButton">
          <Button variant="primary" onClick={()=>submit()}>
            Submit
          </Button>
          </Form.Group>
        </div>
      </div>
    </div>
  );

}


export default ForgotPasswordPage;
