
import {
  GET_QUIZ_REQUEST,
  GET_QUIZ_SUCCESS,
  GET_QUIZ_ERROR,
  GET_ALL_QUIZZES_REQUEST,
  GET_ALL_QUIZZES_SUCCESS,
  GET_ALL_QUIZZES_ERROR,
  GET_SUBMISSIONS_REQUEST,
  GET_SUBMISSIONS_SUCCESS,
  GET_SUBMISSIONS_ERROR,
  GET_ALL_QUIZZES_PUBLIC_REQUEST,
  GET_ALL_QUIZZES_PUBLIC_SUCCESS,
  GET_ALL_QUIZZES_PUBLIC_ERROR
} from '../actions/quiz'

const defaultState = {
  quiz: [],
}

const quiz = (state = defaultState, action) => {

  switch (action.type) {

    case GET_QUIZ_REQUEST:
      return {
        ...state,
        quiz: defaultState.quiz
      }
    case GET_QUIZ_SUCCESS:
      return {
        ...state,
        quiz: action.quiz
      }
    case GET_QUIZ_ERROR:
      return {
        ...state,
        quiz: defaultState.quiz
      }
    case GET_ALL_QUIZZES_REQUEST:
      return {
        ...state,
        quizzes: action.quizzes
      }
    case GET_ALL_QUIZZES_SUCCESS:
      return {
        ...state,
        quizzes: action.quizzes
      }
    case GET_ALL_QUIZZES_ERROR:
      return {
        ...state,
        quizzes: action.quizzes
      }
    case GET_SUBMISSIONS_REQUEST:
      return {
        ...state,
        submissions: [],
      }
    case GET_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        submissions: action.submissions
      }
    case GET_SUBMISSIONS_ERROR:
      return {
        ...state,
        submissions: [],
      }
    case GET_ALL_QUIZZES_PUBLIC_REQUEST:
      return {
        ...state,
        public: [],
      }
    case GET_ALL_QUIZZES_PUBLIC_SUCCESS:
      return {
        ...state,
        public: action.quizzes
      }
    case GET_ALL_QUIZZES_PUBLIC_ERROR:
      return {
        ...state,
        public: [],
      }

    default:
      return state;

  }
}

export default quiz;
