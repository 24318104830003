import React from 'react';
import './styles.scss';
import {Button} from 'react-bootstrap';

function Playground (){
    return (
      <>
      <div className="container-fluid">
        <div className="row" id="playground-container">
          <div className="col-md-12">
            <h1>Kids Playground</h1>
            <p>Learn Basic Skills with Learn With Guruji playground.</p>
            <Button href="/playground/learn-abcd" variant="primary">Fun With ABCD</Button>
          </div>
          <div className="col-md-12">
            <p>If you would like to add more games here do reach us at support@learnwithguruji.com.</p>
          </div>
          <div className="col-md-12">
            <a href="/">Go Back</a>
          </div>
        </div>
      </div>
      </>
    )
}


export default Playground;
