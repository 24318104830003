import React, { Fragment } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import { useState } from 'react';
import moment from 'moment';
import { fileUploadS3 } from '../../../helpers/common';

interface MyUploadType {
    id: string,
    created_at: string,
    url: string
};

export const DashboardGlobalUploader = () => {

    const [myUploads, setMyUploads] = useState<MyUploadType[]>([]);
    const [uploadPercent, setUploadPercent] = useState(0);
    const [uploadButtonText, setUploadButtonText] = useState("Upload From System");
    useEffect(() => {
        getUploadedContents();
    }, []); 
     // eslint-disable-next-line
    const getUploadedContents = () => {
        const token = localStorage.getItem('token') || null;
        axios.get(`${BASE_URL}/upload`, { 'headers': { 'Authorization': "Bearer " + token } })
            .then((resp) => {
                setMyUploads((resp.data.result || []));
            });
    }
    const deleteUploadedContent = (id) => {
        const token = localStorage.getItem('token') || null;
        axios.delete(`${BASE_URL}/upload/${id}`, { 'headers': { 'Authorization': "Bearer " + token } })
            .then((resp) => {
                getUploadedContents();
            });
    }
    const uploadProgress = (percent) => {
        if (percent === 100) {
            setUploadPercent(0);
            setUploadButtonText("Upload From System");
        } else {
            setUploadPercent(percent);
            setUploadButtonText(`Uploading ${percent}%`);
        }
    }
    function onVideoUpload(event) {
        fileUploadS3(event, uploadProgress, getUploadedContents);
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <input disabled={uploadPercent > 0} type="file" id="videoUploadBtn" hidden onChange={onVideoUpload} />
                        <label className="btn btn-primary" htmlFor="videoUploadBtn">{uploadButtonText}</label>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col"><hr /></div>
                            <div className="col-auto">Manage Uploaded Assets</div>
                            <div className="col"><hr /></div>
                        </div>
                    </div>
                    <div className="col-md-12 table-responsive">
                        <table className="table table-striped table-hover  table-bordered">
                            <caption>List of uploaded items.</caption>
                            <thead className="thead-dark">
                                <th>Sr. No.</th>
                                <th>URL</th>
                                <th>Uploaded</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                {myUploads.map((data, index) => <tr key={`upload-id-` + data.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <a href={data.url} rel="noopener noreferrer" target="_blank">{data.url}</a>
                                    </td>
                                    <td>{moment(data.created_at).fromNow()}</td>
                                    <td>
                                        <button className="btn btn-primary" onClick={() => deleteUploadedContent(data.id)}>Remove</button>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </Fragment>
    );
}